import axios from 'axios'
import { getApiRoot } from '../utils/SystemUtils'

export default {
  getServiceUrl () {
    return axios
      .get(`${getApiRoot()}/api/messaging/url`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getConfiguration () {
    return axios
      .get(`${getApiRoot()}/api/messaging/configuration`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  updateConfiguration (url, secret) {
    const payload = {
      url: url,
      secret: secret
    }

    return axios
      .post(`${getApiRoot()}/api/messaging/configuration`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getMessagingUsername (user) {
    return user.email.split('@')[0]
  },

  getUserPresence (userId) {
    return axios
      .get(`${getApiRoot()}/api/messaging/users/${userId}/presence`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  synchronizeSpaceChannel (space) {
    const data = {
      spaceId: space.id
    }

    return axios
      .post(`${getApiRoot()}/api/messaging/channels/synchronize`, data, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  }
}
