import axios from 'axios'
import isUndefined from 'lodash/isUndefined'
import { getApiRoot } from '../utils/SystemUtils'

/*
 * APIs for working with users and their profiles.
 */

export function getUserProfileFiles (userId, contentType, pageNumber, pageSize) {
  // const start = pageNumber - 1
  const payload = {
    parentId: userId,
    contentType: contentType
  }
  return axios
    .post(`${getApiRoot()}/api/storage/assets/search`, payload)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}
export function getFeatured (userId, featuredIds) {
  const payload = {
    parentId: userId,
    ids: featuredIds
  }
  return axios
    .post(`${getApiRoot()}/api/storage/assets/search`, payload)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}
export function updateUserAccountDetails (firstName, lastName, email, timezone, language) {
  const payload = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    timezone: timezone,
    language: language
  }
  return axios
    .post(`${getApiRoot()}/api/users/details`, payload)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export default {
  token: '',

  getOrgUsers (pageNumber, pageSize) {
    const start = pageNumber - 1
    return axios
      .get(`${getApiRoot()}/api/users?start=${start}&limit=${pageSize}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getUserInfo (uid) {
    return axios
      .get(`${getApiRoot()}/api/users/${uid}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  changeUserPassword (userId, currentPassword, newPassword) {
    const payload = {
      currentPassword: currentPassword,
      newPassword: newPassword
    }

    return axios
      .post(`${getApiRoot()}/api/users/${userId}/password`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  changeDefaultSpace (userId, defaultSpace) {
    const payload = {
      defaultSpace: defaultSpace
    }

    return axios
      .post(`${getApiRoot()}/api/users/${userId}/preferences`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
  },

  getPasswordResetDetails (token) {
    return axios
      .get(`${getApiRoot()}/api/passwords/${token}`, {
        headers: {
          'Tenant-Domain': window.location.hostname
        }
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  requestPasswordReset (username) {
    const payload = {
      username: username
    }

    return axios
      .post(`${getApiRoot()}/api/passwords/reset`, payload, {
        'Content-Type': 'application/json',
        headers: {
          'Tenant-Domain': window.location.hostname
        }
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  resetUserPassword (token, password) {
    const payload = {
      token: token,
      password: password
    }

    return axios
      .post(`${getApiRoot()}/api/passwords`, payload, {
        'Content-Type': 'application/json',
        headers: {
          'Tenant-Domain': window.location.hostname
        }
      })
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
  },

  invalidatePasswordReset (token) {
    return axios
      .delete(`${getApiRoot()}/api/passwords/${token}`, {
        'Content-Type': 'application/json',
        headers: {
          'Tenant-Domain': window.location.hostname
        }
      })
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
  },

  getProvisionDetails (token) {
    return axios
      .get(`${getApiRoot()}/api/provisions/${token}`, {
        headers: {
          'Tenant-Domain': window.location.hostname
        }
      })
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
  },

  completeProvisionSetup (token, password) {
    const payload = {
      token: token,
      password: password
    }

    return axios
      .post(`${getApiRoot()}/api/provisions`, payload, {
        'Content-Type': 'application/json',
        headers: {
          'Tenant-Domain': window.location.hostname
        }
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getInvitationDetails (token) {
    return axios
      .get(`${getApiRoot()}/api/invitations/token/${token}`, {
        headers: {
          'Tenant-Domain': window.location.hostname
        }
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  acceptInvitation (token, firstName, lastName, password) {
    const payload = {
      firstName: firstName,
      lastName: lastName,
      password: password
    }

    return axios
      .post(`${getApiRoot()}/api/invitations/token/${token}`, payload, {
        headers: {
          'Tenant-Domain': window.location.hostname
        }
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  registerUser (registrationData, invitationToken) {
    const payload = {
      data: registrationData
    }
    if (!isUndefined(invitationToken)) {
      payload.token = invitationToken
    }
    return axios
      .post(`${getApiRoot()}/api/registration`, payload, {
        headers: {
          'Tenant-Domain': window.location.hostname
        }
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getGroups (page, pageSize, sortField, sortDirection) {
    const start = page - 1
    return axios
      .get(`${getApiRoot()}/api/groups?start=${start}&limit=${pageSize}&sort=${sortField}&sortDirection=${sortDirection}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  createGroup (name, description) {
    const payload = {
      name: name,
      description: description
    }

    return axios
      .put(`${getApiRoot()}/api/groups`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  deleteGroup (groupId) {
    return axios
      .delete(`${getApiRoot()}/api/groups/${groupId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  searchGroupByName (query) {
    return axios
      .get(`${getApiRoot()}/api/search/groups/${query}`)
      .then(response => {
        return response.data.results
      })
      .catch(error => {
        throw error
      })
  },

  addUserToGroup (userId, groupId) {
    const payload = {
      userId: userId
    }

    return axios
      .post(`${getApiRoot()}/api/groups/${groupId}/members`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  deleteUserFromGroup (userId, groupId) {
    return axios
      .delete(`${getApiRoot()}/api/groups/${groupId}/members/${userId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getGroupMembers (page, pageSize, groupId, sortField, sortDirection) {
    const start = page - 1
    return axios
      .get(`${getApiRoot()}/api/groups/${groupId}/members?start=${start}&limit=${pageSize}&sort=${sortField}&sortDirection=${sortDirection}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  updateAvatar (userId, avatar) {
    const payload = {
      avatar: avatar
    }

    return axios
      .post(`${getApiRoot()}/api/users/${userId}/avatar`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  setUserAttributes (uid, attributes) {
    return axios
      .post(`${getApiRoot()}/api/users/${uid}/attributes`, attributes)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getProfileFieldTypes () {
    return axios
      .get(`${getApiRoot()}/api/profile/fields/types`)
      .then(response => {
        return response.data
      })
      .catch(error => {
        throw error
      })
  },

  getProfileFields () {
    return axios
      .get(`${getApiRoot()}/api/profile/fields`)
      .then(response => {
        return response.data
      })
      .catch(error => {
        throw error
      })
  },

  addProfileField (profileField) {
    return axios
      .post(`${getApiRoot()}/api/profile/fields`, profileField, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        throw error
      })
  },

  deleteProfileField (profileFieldId) {
    return axios
      .delete(`${getApiRoot()}/api/profile/fields/${profileFieldId}`)
      .then(response => {
        return response.data
      })
      .catch(error => {
        throw error
      })
  },

  updateProfileField (profileFieldId, name, description) {
    const data = {
      name: name,
      description: description
    }

    return axios
      .post(`${getApiRoot()}/api/profile/fields/${profileFieldId}`, data, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        throw error
      })
  },

  toggleFieldVisibility (profileFieldId, isVisible) {
    const data = {
      isVisible: isVisible
    }
    return axios
      .post(`${getApiRoot()}/api/profile/fields/${profileFieldId}/visible`, data, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        throw error
      })
  },

  toggleFieldRequirement (profileFieldId, isRequired) {
    const data = {
      isRequired: isRequired
    }
    return axios
      .post(`${getApiRoot()}/api/profile/fields/${profileFieldId}/mandatory`, data, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        throw error
      })
  },

  searchUsersWithFilters (query, filterValues) {
    const data = {
      query: query,
      filters: filterValues
    }

    return axios
      .post(`${getApiRoot()}/api/search/users`, data)
      .then(response => {
        const mapped = response.data.map(user => {
          return {
            ...user,
            searchDisplayName: `${user.fullName} (${user.email})`
          }
        })

        return mapped
      })
      .catch(error => {
        throw error
      })
  },

  updateUserStatus (userId, isActive) {
    const data = {
      isActive: isActive
    }
    return axios
      .post(`${getApiRoot()}/api/users/${userId}/status`, data)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  deleteOrgUser (userId) {
    return axios
      .delete(`${getApiRoot()}/api/admin/users/${userId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  }
}
