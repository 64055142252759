import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { getApiRoot } from '../utils/SystemUtils'

export function assetUrl (parentId, key) {
  return `${getApiRoot()}/files/${parentId}/${key}`
}

export default {
  setupStorage () {
    return axios
      .post(
        `${getApiRoot()}/api/storage`,
        {},
        {
          'Content-Type': 'application/json'
        }
      )
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
  },

  /**
   * Publishes a draft content.
   */
  publishContent (contentId, title, content, properties, spaceId) {
    const payload = {
      contentId: contentId,
      title: title,
      content: content,
      properties: properties
    }

    return axios
      .post(`${getApiRoot()}/api/spaces/${spaceId}/contents`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        console.log(error)
        throw error
      })
  },

  saveDraft (draftId, title, content, ogImageId) {
    const payload = {
      title: title,
      content: content,
      properties: {
        ogImageId: ogImageId
      }
    }

    return axios
      .post(`${getApiRoot()}/api/drafts/${draftId}`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  /**
   * Creates a new draft content.
   */
  createDraft (experienceId, postType) {
    const payload = {
      type: postType
    }

    return axios
      .post(`${getApiRoot()}/api/spaces/${experienceId}/drafts`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  postContent (content, instanceId, zoneId) {
    const payload = {
      content: content,
      instanceId: instanceId,
      zoneId: zoneId
    }

    return axios
      .post(`${getApiRoot()}/api/contents`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  updateContent (title, body, properties, contentId) {
    const payload = {
      content: body,
      title: title,
      properties: properties
    }

    return axios
      .post(`${getApiRoot()}/api/contents/${contentId}`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getContent (ownerId) {
    return axios
      .get(`${getApiRoot()}/api/contents/${ownerId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  /**
   * Get all attachments for a given page. Results are paginated.
   *
   * @param pageNumber the current pageing number
   * @param pageSize   how many items to include
   * @param pageId     id of the page
   * @returns  paginated response
   */
  getAssetsForPage (pageNumber, pageSize, pageId, sortField = '', sortDirection = '') {
    const start = pageNumber - 1
    return axios
      .get(`${getApiRoot()}/api/storage/assets?parentId=${pageId}&start=${start}&limit=${pageSize}&sort=${sortField}&sortDirection=${sortDirection}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  /**
   * Search for relevant assets.
   *
   * @param contentTypes types of assets to return (array)
   * @param context      additional context to look under
   * @param parentId     direct parent entity to look under
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  searchAssets (contentTypes, context, parentId) {
    const payload = {
      contentTypes: contentTypes,
      context: context,
      parentId: parentId
    }

    return axios
      .post(`${getApiRoot()}/api/storage/assets/attachable`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  createAsset (file, parentId, context) {
    return this.createAssetWithCustomName(file, parentId, context, file.name)

    /*
    let payload = {
      name: file.name,
      key: file.name,
      size: file.size,
      contentType: file.type,
      lastModified: file.lastModified,
      parentId: parentId,
      context: context
    }

    return axios
      .post(`${getApiRoot()}/api/storage/assets`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
     */
  },

  createAssetWithCustomName (file, parentId, context, customName) {
    const payload = {
      name: customName,
      key: customName,
      size: file.size,
      contentType: file.type,
      lastModified: file.lastModified,
      parentId: parentId,
      context: context
    }

    return axios
      .post(`${getApiRoot()}/api/storage/assets`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getUploadUrl (fileName, contentType, context, isPublic = false) {
    if (isEmpty(context)) {
      context = ''
    }
    const data = {
      context: context,
      fileName: fileName,
      contentType: contentType,
      isPublic: isPublic
    }

    return axios
      .post(`${getApiRoot()}/api/storage/assets/url/upload`, data)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  deleteAsset (id) {
    return axios
      .delete(`${getApiRoot()}/api/storage/assets/${id}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  deleteAssets (assetIds) {
    const payload = {
      ids: assetIds
    }
    return axios
      .post(`${getApiRoot()}/api/storage/assets/bulk/delete`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getExpiringAssetUrl (id) {
    return axios
      .get(`${getApiRoot()}/api/storage/assets/${id}/url/presigned`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getDownloadUrlById (id) {
    return axios
      .get(`${getApiRoot()}/api/storage/assets/${id}/url/download`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getExpiringAssetUrlByKey (key, parentId) {
    return axios
      .get(`${getApiRoot()}/api/storage/assets/url/download?key=${key}&parentId=${parentId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getSnippets (page, pageSize) {
    const start = page - 1
    return axios
      .get(`${getApiRoot()}/api/content/snippets?start=${start}&limit=${pageSize}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getSnippet (id) {
    return axios
      .get(`${getApiRoot()}/api/content/snippets/${id}`)
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
  },

  deleteSnippet (id) {
    return axios
      .delete(`${getApiRoot()}/api/content/snippets/${id}`)
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
  },

  createSnippet (name, description, content) {
    const payload = {
      name: name,
      description: description,
      content: content
    }

    return axios
      .put(`${getApiRoot()}/api/content/snippets`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
  },

  updateSnippet (id, name, description, content) {
    const payload = {
      name: name,
      description: description,
      content: content
    }

    return axios
      .post(`${getApiRoot()}/api/content/snippets/${id}`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
  },

  getContents (spaceId, payload, page, pageSize, includeDraft) {
    const start = page - 1
    return axios
      .post(`${getApiRoot()}/api/spaces/${spaceId}/contents/search?start=${start}&limit=${pageSize}&includeDraft=${includeDraft}`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getExperienceDrafts (experienceId, filter, page, pageSize) {
    const start = page - 1
    return axios
      .post(`${getApiRoot()}/api/experience/${experienceId}/contents/draft?start=${start}&limit=${pageSize}`, filter)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  searchContents (contentType, tags = [], experienceIds, includeDraft, page, pageSize, order) {
    const start = page - 1
    const payload = {
      type: contentType,
      tags: tags,
      experienceIds: experienceIds,
      includeDraft: includeDraft,
      orderString: order
    }
    return axios
      .post(`${getApiRoot()}/api/contents/search?start=${start}&limit=${pageSize}`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getContentItem (contentId, experienceKey) {
    return (
      axios
        .get(`${getApiRoot()}/api/contents/${contentId}`)
        // .get(`${getApiRoot()}/api/v2/experiences/${experienceKey}/contents/${contentId}`)
        .then(response => {
          return response
        })
        .catch(error => {
          throw error
        })
    )
  },

  getContentItemByAdiId (contentId) {
    return (
      axios
        .get(`${getApiRoot()}/api/contents/${contentId}/adi`)
        // .get(`${getApiRoot()}/api/v2/experiences/${experienceKey}/contents/${contentId}`)
        .then(response => {
          return response
        })
        .catch(error => {
          throw error
        })
    )
  },

  deleteContentItem (contentId, spaceId) {
    return axios
      .delete(`${getApiRoot()}/api/spaces/${spaceId}/contents/${contentId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getContext (spaceId, entityId) {
    if (spaceId === 'undefined' || isEmpty(spaceId)) {
      return 'global'
    } else {
      if (entityId === 'undefined' || isEmpty(entityId)) {
        return spaceId
      } else {
        return `${spaceId}/${entityId}`
      }
    }
  },

  getParentId (entityId) {
    if (entityId === 'undefined' || isEmpty(entityId)) {
      return ''
    } else {
      return entityId
    }
  }
}
