import React, { createRef, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { search } from '../../common/apis/SearchApi'
import { SearchContentLoader, SearchMetaLoader } from '../../legacy/components'
import PaginationLoader from '../loaders/PaginationLoader'
import SearchResult from './SearchResult'
import BootstrapPagination from '../pagination/BootstrapPagination'

import searchContents from './images/undraw_file_searching_duff.svg'

import './Search.css'

/**
 * Component rendering search result page/panel.
 */
const Search = ({ handleState }) => {
  let searchFieldRef = createRef()
  const [searchResult, setSearchResult] = useState({
    isResultReady: false,
    isSearching: false,
    query: null
  })

  const handleSearch = event => {
    event.preventDefault()
    const data = new window.FormData(event.target)
    searchContent(data.get('query'), 0, true)
  }

  const renderSearchMeta = searchResult => {
    if (searchResult.isSearching && !searchResult.isResultReady) {
      return (
        <>
          <SearchMetaLoader />
          <div className="mb-4">
            <PaginationLoader />
          </div>
        </>
      )
    } else {
      if (!isEmpty(searchResult.results)) {
        return (
          <div className="search meta mb-3">
            <p>
              {searchResult.size} search results.
            </p>

            <div className="btn-toolbar" role="toolbar">
              <BootstrapPagination current={searchResult.start + 1} total={searchResult.size}
                pageSize={searchResult.limit}
                onChange={onPage} locale={{}} showTitle={false} showLessItems isSuppressHash={true}/>
            </div>
          </div>
        )
      } else {
        return null
      }
    }
  }

  const onPage = page => {
    searchContent(searchFieldRef.value, page, false)
  }

  const searchContent = (query, page, isNewSearch) => {
    setSearchResult({
      ...searchResult,
      isSearching: true,
      isResultReady: !isNewSearch
    })
    if (page > 0) {
      page--
    }

    search(query, page)
      .then(results => {
        setSearchResult({
          isResultReady: true,
          isSearching: false,
          query: query,
          ...results.data
        })
      })
      .catch(error => {
        setSearchResult({
          isResultReady: true,
          isSearching: false,
          query: query,
          error: error.error
        })
      })
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <form className="search-form" role="search" onSubmit={handleSearch}>
            <div className="input-group mb-3">
              <input id="query" autoFocus name="query" type="text" className="form-control" placeholder="Search for content"
                ref={el => { searchFieldRef = el }} required />
              <div className="input-group-append">
                <button className="search-btn btn btn-primary" disabled={searchResult.isSearching}>
                  <span className="fa fa-search" />
                </button>
              </div>
              <div className="search-control-separator">
                <button type="button" className="btn" title="Close" onClick={() => handleState(false)}>
                  <span className="fa fa-times" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="search-result stream">
        {!searchResult.isSearching && !searchResult.isResultReady ? (
          <div style={{ marginTop: '10em' }}>
            <img src={searchContents} alt="search-contents" className="search-placeholder" />
          </div>
        ) : (
          <>
            {renderSearchMeta(searchResult)}
            {searchResult.isSearching ? (
              <div className="list-group list-group-flush">
                <SearchContentLoader />
                <SearchContentLoader />
                <SearchContentLoader />
              </div>
            ) : (
              isEmpty(searchResult.results)) ? (
                <div className="search meta">
                  <p>
                    No results found for <strong>{searchResult.query}</strong>.
                  </p>
                </div>
              ) : (
                <>
                  <div className="search-result-list list-group list-group-flush">
                    {searchResult.results.map((item, index) => (
                      <SearchResult key={index} item={item} />
                    ))}
                  </div>
                </>
              )}
          </>
        )}
      </div>
    </>
  )
}

Search.propTypes = {
  handleState: PropTypes.func.isRequired
}

export default Search
