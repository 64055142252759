import slugify from 'slugify'
import isEmpty from 'lodash/isEmpty'

export const slugifyString = (value) => {
  if (isEmpty(value)) {
    return ''
  } else {
    return slugify(value, {
      lower: true,
      strict: true
    })
  }
}

export const isUrlAbsolute = (url) => {
  return (url.indexOf('://') > 0 || url.indexOf('//') === 0)
}
