import axios from 'axios'
import { getApiRoot } from '../../utils/SystemUtils'

export default {

  getExperienceByKey (key) {
    return axios
      .get(`${getApiRoot()}/api/v2/experiences/${key}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  createPersonalExperience (name, description) {
    const payload = {
      name: name,
      description: description,
      type: 'personal'
    }

    return axios
      .put(`${getApiRoot()}/api/spaces/personal`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  }
}
