import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import NotAuthenticatedLayout from '../../../components/layouts/NotAuthenticatedLayout'
import MainLayout from '../../../components/layouts/MainLayout'

import './Status.css'

/**
 * A generic 404 page
 */
export default function Status404 () {
  const { t } = useTranslation()
  const isAuthed = useSelector(state => state.users.authedId) !== ''

  const body = () => (
    <div className="status Status404 error-card">
      <div className="error-title-block text-center ">
        <div>
          <img src="/images/undraw/undraw_artificial_intelligence.svg" alt="" />
        </div>
        <h1 className="error-sub-title">{t('errors.notFound.title')}</h1>
        <h2 className="pt-4 h5">{t('errors.notFound.description')}</h2>
        <div className="pt-4 mb-0">
          <Link to="/" className="btn btn-primary">
            {t('errors.homeLink')}
          </Link>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <Helmet defer={false}>
        <title>{t('errors.notFound.documentTitle')}</title>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      {isAuthed ? (
        <MainLayout>{body()}</MainLayout>
      ) : (
        <NotAuthenticatedLayout>{body()}</NotAuthenticatedLayout>
      )}
    </>
  )
}
