import axios from 'axios'
import { getApiRoot } from '../utils/SystemUtils'

export default {
  getFavorites () {
    return axios
      .get(`${getApiRoot()}/api/favorites`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  addExperience (experienceId) {
    return axios
      .post(`${getApiRoot()}/api/favorites/add/experience`, { id: experienceId })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  removeExperience (experienceId) {
    return axios
      .post(`${getApiRoot()}/api/favorites/remove/experience`, { id: experienceId })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  }
}
