import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { getApiRoot } from '../utils/SystemUtils'

export function getExperiencesByIds (ids) {
  return axios
    .get(`${getApiRoot()}/api/v2/experiences?ids=${ids}`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export function getUserExperiences () {
  return axios
    .get(`${getApiRoot()}/api/v2/experiences/my`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export function searchExperiences (query, page, pageSize = 12) {
  const start = page - 1
  const data = {
    query: query
  }
  return axios
    .post(`${getApiRoot()}/api/v2/search/experiences?start=${start}&limit=${pageSize}`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export function updateNavigation (id, navigation) {
  return axios
    .post(`${getApiRoot()}/api/v2/experiences/${id}/navigation`, navigation)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export function updateSocialSettings (id, socialSettings) {
  return axios
    .post(`${getApiRoot()}/api/v2/experiences/${id}/settings/social`, socialSettings)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export function setMemberRole (newRole, member, experienceId) {
  const payload = {
    role: newRole
  }

  return axios
    .post(`${getApiRoot()}/api/spaces/${experienceId}/members/${member.id}`, payload)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export function getAvailableRoles () {
  return axios
    .get(`${getApiRoot()}/api/members/options`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

/**
 * APIs for working with experiences.
 */
export default {
  EXPERIENCE_TYPE_GLOBAL: 'global',

  EXPERIENCE_TYPE_PERSONAL: 'personal',

  getExperienceByKey (key) {
    return axios
      .get(`${getApiRoot()}/api/admin/experiences/${key}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getExperiences (page, pageSize = 12) {
    const start = page - 1
    return axios
      .get(`${getApiRoot()}/api/spaces/my?start=${start}&limit=${pageSize}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getExperiencesAsAdmin (query, page, pageSize, fields, sortField = '', sortDirection = '') {
    const start = page - 1
    const payload = {
      query: query,
      fields: isEmpty(fields) ? [] : fields
    }
    return axios
      .post(`${getApiRoot()}/api/admin/search/experiences?start=${start}&limit=${pageSize}&sort=${sortField}&sortDirection=${sortDirection}`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  createExperience (name, key, description, type, blueprintId) {
    const payload = {
      key: key,
      name: name,
      description: description,
      type: type,
      blueprintId: blueprintId
    }
    return axios
      .put(`${getApiRoot()}/api/spaces`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  deleteExperience (experienceId) {
    return axios
      .delete(`${getApiRoot()}/api/spaces/${experienceId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  updateExperience (experienceId, data) {
    return axios
      .post(`${getApiRoot()}/api/spaces/${experienceId}`, data)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  updateExperienceBranding (experienceId, payload) {
    return axios
      .post(`${getApiRoot()}/api/spaces/${experienceId}`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },
  updateExperienceIcon (experienceId, logoBase64) {
    const payload = {
      image: logoBase64
    }

    return axios
      .post(`${getApiRoot()}/api/spaces/${experienceId}/icon`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  updateRowSettings (experienceId, pageId, rowId, settings) {
    const data = {
      settings: settings
    }
    return axios
      .post(`${getApiRoot()}/api/spaces/${experienceId}/pages/${pageId}/rows/${rowId}/settings`, data)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  }
}
