import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Icon from 'react-fa'
import { useTranslation } from 'react-i18next'

import Constants from '../../../common/utils/Constants'
import Permissions from '../../../common/utils/Permissions'
import { getHomepageUrl } from '../../../common/apis/Api'

import './MenuDrawer.css'

/**
 * Component for the left navigation menu.
 */
const MenuDrawer = ({ isOpen, handleState }) => {
  const { t } = useTranslation()
  const users = useSelector(state => state.users)
  const tenant = useSelector(state => state.tenant.info)

  const authedUser = users[users.authedId] || null
  const isOrgAdmin = authedUser && authedUser.info.permissions.indexOf(Permissions.PERMISSION_ORG_ADMIN) > -1
  const isDesigner = authedUser && authedUser.info.permissions.indexOf(Permissions.PERMISSION_CONTENT_AUTHOR) > -1

  return isOpen && (
    <>
      <div className="blanket menu-drawer" onClick={() => handleState(false)} />
      <div id="MenuDrawer" className="MenuDrawer">
        <nav className="nav flex-column">
          <NavLink exact to={getHomepageUrl(authedUser, tenant)}>
            <Icon name="home" />&nbsp;{t('navigation.main.home')}
          </NavLink>
          <NavLink to={Constants.EXPERIENCES_BASE_URL}>
            <Icon name="map-o" />&nbsp;{t('navigation.main.experiences')}
          </NavLink>
          {authedUser && (
            <NavLink to="/users">
              <Icon name="users" />&nbsp;{t('navigation.main.users')}
            </NavLink>
          )}
          {isDesigner && (
            <NavLink to="/design">
              <Icon name="flask" />&nbsp;{t('navigation.main.design')}
            </NavLink>
          )}
          {isOrgAdmin && (
            <NavLink to="/admin">
              <Icon name="university" />&nbsp;{t('navigation.main.administration')}
            </NavLink>
          )}
        </nav>
      </div>
    </>
  )
}

export default MenuDrawer
