import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import Icon from 'react-fa'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import Constants from '../../common/utils/Constants'
import EditingBlanket from '../editing/EditingBlanket'
import { getHomepageUrl } from '../../common/apis/Api'

import anonymousAvatar from './images/anonymous.png'
import browse from './images/layers.svg'
import create from './images/writing.svg'
import search from './images/search.svg'
import login from './images/login.svg'

const MainHeader = ({ handleSearchIsDrawerOpen, handleMenuIsDrawerOpen, selectContentType, menuIsDrawerOpen }) => {
  // react hooks
  const [redirectTo, setRedirectTo] = useState(null)

  // redux hooks
  const tenant = useSelector(state => state.tenant.info)
  const currentUser = useSelector(state => state.users[state.users.authedId])
  const editing = useSelector(state => state.editing)

  // i18next hooks
  const { t } = useTranslation()

  const handleSearch = event => {
    event.preventDefault()
    const q = event.target.elements.query.value
    setRedirectTo('/search?query=' + encodeURIComponent(q))
  }
  const handleLogout = () => {
    // log out of messaging, since we are posting messages to the iframe, we need to be on a page where the iframe is showing
    const messagingFrame = document.querySelector('iframe.am-frame')
    if (!isEmpty(messagingFrame)) {
      messagingFrame.contentWindow.postMessage(
        {
          externalCommand: 'logout'
        },
        '*'
      )
    }
  }

  return redirectTo ? (
    <Redirect to={redirectTo} />
  ) : (
    <>
      <header className="MainHeader">
        <ReactTooltip id="headerTooltip" place="bottom" event='mouseover' eventOff='mouseout click' effect="solid" />
        <EditingBlanket />
        <nav className="navbar navbar-expand">
          {currentUser && (
            <button
              onClick={() => handleMenuIsDrawerOpen(!menuIsDrawerOpen)}
              type="button"
              data-target="#MenuDrawer"
              aria-controls="MenuDrawer"
              className={`btn collapse-btn MenuDrawer-trigger ${menuIsDrawerOpen ? 'open' : ''}`}
              id="sidebar-collapse-btn"
              style={{ marginLeft: -10, zIndex: editing && editing.isActive ? 0 : 500 }}
            >
              <Icon name="bars" />
              <Icon name="times" />
            </button>
          )}
          <Link className={`navbar-brand ${currentUser ? 'authed' : 'unauthed'}`} to={getHomepageUrl(currentUser, tenant)}>
            <img src={tenant.branding && tenant.branding.logo ? tenant.branding.logo : '/images/aloha-tm.svg'} alt="logo"
              height={(!tenant.branding || !tenant.branding.logo) ? '32' : ''} style={{ maxHeight: 32, maxWidth: 150 }} />
          </Link>

          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav mr-auto" />
            {currentUser && (
              <>
                <div className="create-content-dropdown dropdown">
                  <Link to="#" id="createTrigger" className="mr-3 dropdown-toggle" data-for="headerTooltip" data-tip="Create Content" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={create} alt="create-content" width="28px" height="28px" />
                  </Link>
                  <div className="dropdown-menu create-dropdown-menu mr-3" aria-labelledby="createTrigger">
                    <h4 className="dropdown-header">Create</h4>
                    <div className="dropdown-divider" />
                    <Link to="#" className="dropdown-item" onClick={e => selectContentType(e, 'post')}>
                      Post
                    </Link>
                    <Link to="#" className="dropdown-item" onClick={e => selectContentType(e, 'blog')}>
                      Blog
                    </Link>
                    <Link to="#" className="dropdown-item" onClick={e => selectContentType(e, 'discussion')}>
                      Discussion
                    </Link>
                    <Link to="#" className="dropdown-item" onClick={e => selectContentType(e, 'event')}>
                      Event
                    </Link>
                  </div>
                </div>
              </>
            )}
            <div>
              <Link className="mr-3" data-for="headerTooltip" data-tip="Browse Experiences" to={Constants.EXPERIENCES_BASE_URL}>
                <img src={browse} alt="browse-experience" width="28px" height="28px" />
              </Link>
            </div>
            {currentUser ? (
              <>
                <Link className="search-button mr-3" to="#" onClick={() => handleSearchIsDrawerOpen(true)}>
                  <img src={search} alt="search" width="28px" height="28px" />
                </Link>
                <form className="search-form form-inline" onSubmit={e => handleSearch(e)}>
                  <input className="form-control mr-sm-4" type="text" aria-label="Search" name="query" placeholder={t('app.header.search')} onFocus={() => handleSearchIsDrawerOpen(true)} />
                </form>
                <div className="dropdown">
                  <button id="profileTrigger" type="button" className="btn btn-link profile-toggle p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={currentUser ? currentUser.info.avatar : anonymousAvatar} alt="" className="rounded-circle" />
                  </button>
                  <div id="profileDropdown" className="dropdown-menu profile-dropdown-menu dropdown-menu-right" aria-labelledby="profileTrigger">
                    {/*
                    <Link to={`${Constants.EXPERIENCES_BASE_URL}/${currentUser.info.id}`} className="dropdown-item">
                      <span className="fa fa-globe" /> &nbsp;My Experience
                    </Link>
                    */}
                    <Link to={`/users/${currentUser.info.id}`} className="dropdown-item">
                      <span className="fa fa-user-circle" /> &nbsp;Profile
                    </Link>
                    <div className="dropdown-divider" />
                    <Link className="dropdown-item" to="/logout" onClick={handleLogout}>
                      <span className="fa fa-power-off" /> &nbsp;Logout
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Link className="login-button" data-for="headerTooltip" data-tip="Log in" to={`/auth?redirectTo=${encodeURIComponent(window.location.pathname)}`}>
                  <img src={login} alt="log in" width="28px" height="28px" />
                </Link>
              </>
            )}
          </div>
        </nav>
      </header>
    </>
  )
}

MainHeader.propTypes = {
  handleSearchIsDrawerOpen: PropTypes.func.isRequired,
  handleMenuIsDrawerOpen: PropTypes.func.isRequired,
  selectContentType: PropTypes.func.isRequired,
  menuIsDrawerOpen: PropTypes.bool.isRequired
}

export default MainHeader
