import axios from 'axios'
import { getApiRoot } from '../utils/SystemUtils'

export function getPage (experienceKey, pageId) {
  return axios
    .get(`${getApiRoot()}/api/v2/experiences/${experienceKey}/pages/${pageId}`)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export default {
  PAGE_LAYOUTS: [
    {
      name: 'Rows',
      logo: 'rows.png'
    },
    {
      name: '2 Columns',
      logo: '2-columns.png'
    },
    {
      name: '3 Columns',
      logo: '3-columns.png'
    },
    {
      name: 'Article',
      logo: 'article.png'
    },
    {
      name: 'Masonry',
      logo: 'masonry.png'
    },
    {
      name: 'Free Style',
      logo: 'free-style.png'
    }
  ],

  createPage (experienceId, payload) {
    return axios
      // .put(`${getApiRoot()}/api/spaces/${experienceId}/zones`, payload)
      .put(`${getApiRoot()}/api/v2/experiences/${experienceId}/pages`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  updatePage (experienceId, pageId, pageData) {
    return axios
      .post(`${getApiRoot()}/api/v2/pages/${pageId}`, pageData)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  deletePage (experienceId, pageId) {
    return axios
      .delete(`${getApiRoot()}/api/spaces/${experienceId}/zones/${pageId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getPagesForExperience (experienceId, status, page, pageSize, sortField = '', sortDirection = '') {
    const start = page - 1
    return axios
      .get(`${getApiRoot()}/api/experiences/${experienceId}/pages?status=${status}&start=${start}&limit=${pageSize}&sort=${sortField}&sortDirection=${sortDirection}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  updatePageStatus (pageId, status) {
    const data = {
      status: status
    }
    return axios
      .post(`${getApiRoot()}/api/pages/${pageId}/status`, data)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  searchPage (experienceId, query) {
    const data = {
      experienceId: experienceId,
      query: query
    }
    return axios
      .post(`${getApiRoot()}/api/pages/search`, data)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  clonePage (pageId, newPageName) {
    const data = {
      pageName: newPageName
    }
    return axios
      .post(`${getApiRoot()}/api/pages/${pageId}/clone`, data)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  }
}
