import L from 'react-loadable'
import { LoadingIndicator } from '../../legacy/components'

export default {
  // https://twitter.com/matzatorski/status/872210207132319744
  Loadable: opts =>
    L({
      loading: opts.loading || LoadingIndicator,
      delay: 400,
      ...opts
    })
}
