/**
 * Servicing the top level `tenant` object in the redux store
 */

import api from '../../common/apis/Api'
import tenantApi from '../../common/apis/TenantApi'

/**
 * Actions
 */
const FETCHING_TENANT = 'FETCHING_TENANT'
const FETCHING_TENANT_FAILURE = 'FETCHING_TENANT_FAILURE'
const FETCHING_TENANT_SUCCESS = 'FETCHING_TENANT_SUCCESS'

const UPDATING_TENANT = 'UPDATING_TENANT'
const UPDATING_TENANT_FAILURE = 'UPDATING_TENANT_FAILURE'
const UPDATING_TENANT_SUCCESS = 'UPDATING_TENANT_SUCCESS'

const UPDATE_TENANT_BRANDING = 'UPDATE_TENANT_BRANDING'
const UPDATE_TENANT_PROPERTIES = 'UPDATE_TENANT_PROPERTIES'

/**
 * Initial states for reducers
 */
const initialTenantState = {
  isFetching: false,
  error: null,
  info: {}
}

/**
 * Reducer for users.[user]
 */

export default function tenant (state = initialTenantState, action) {
  switch (action.type) {
    case FETCHING_TENANT:
      return {
        ...state,
        isFetching: true
      }
    case FETCHING_TENANT_SUCCESS:
      return {
        ...state,
        info: action.tenant,
        isFetching: false
      }
    case FETCHING_TENANT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: 'error.tenant.fetching'
      }
    case UPDATING_TENANT_SUCCESS:
      return {
        ...state,
        info: {
          ...action.tenant,
          properties: state.info.properties
        }
      }
    case UPDATE_TENANT_BRANDING:
      return {
        ...state,
        info: {
          ...state.info,
          branding: action.branding
        }
      }
    case UPDATE_TENANT_PROPERTIES:
      return {
        ...state,
        info: {
          ...state.info,
          properties: {
            ...action.properties
          }
        }
      }
    default:
      return state
  }
}

/**
 * Action creators for fetching tenant
 */
function fetchingTenant () {
  return {
    type: FETCHING_TENANT
  }
}

function fetchingTenantFailure () {
  return {
    type: FETCHING_TENANT_FAILURE
  }
}

function fetchingTenantSuccess (tenant) {
  return {
    type: FETCHING_TENANT_SUCCESS,
    tenant
  }
}

export function fetchAndHandleTenant (dispatch) {
  dispatch(fetchingTenant())
  return api
    .getTenant()
    .then(response => {
      dispatch(fetchingTenantSuccess(response.data.tenant))
      return response
    })
    .catch(error => {
      dispatch(fetchingTenantFailure(error))
      throw error
    })
}

/**
 * Action creators for updating tenant info
 */
function updatingTenant () {
  return {
    type: UPDATING_TENANT
  }
}

function updatingTenantFailure () {
  return {
    type: UPDATING_TENANT_FAILURE
  }
}

function updatingTenantSuccess (tenant) {
  return {
    type: UPDATING_TENANT_SUCCESS,
    tenant
  }
}

function updateTenantBranding (branding) {
  return {
    type: UPDATE_TENANT_BRANDING,
    branding
  }
}

function updateTenantProperties (properties) {
  return {
    type: UPDATE_TENANT_PROPERTIES,
    properties
  }
}

export function handleUpdateTenant (tenantId, settings, dispatch) {
  dispatch(updatingTenant())
  return tenantApi
    .updateOrgSettings(tenantId, settings)
    .then(response => {
      dispatch(updatingTenantSuccess(response.data))
      return response
    })
    .catch(error => {
      dispatch(updatingTenantFailure(error))
      throw error
    })
}

export function handleUpdateTenantBranding (tenantId, newBranding, dispatch) {
  dispatch(updateTenantBranding(newBranding))
}

export function handleUpdateTenantProperties (properties, dispatch) {
  dispatch(updateTenantProperties(properties))
}
