import React from 'react'
import PropTypes from 'prop-types'

import './LoadingIndicator.css'

export default function LoadingIndicator ({ loadingText = 'Loading...' }) {
  return (
    <div className="w-100">
      <div title={loadingText} className="loading indicator" />
    </div>
  )
}

LoadingIndicator.propTypes = {
  loadingText: PropTypes.string
}
