import React, { useEffect, useState } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Favicon from 'react-favicon'
import ReactGA from 'react-ga'
import isEmpty from 'lodash/isEmpty'
import { createBrowserHistory } from 'history'

import Constants from './common/utils/Constants'

import Notifications from './components/notifications/Notifications'
import RootContainer from './legacy/containers/Root/RootContainer'
import Status404 from './legacy/components/Status/Status404'

import Async from './common/utils/Async'
import PrivateRoute from './components/routing/PrivateRoute'
import AnonymousRoute from './components/routing/AnonymousRoute'
import ScrollToTop from './components/routing/ScrollToTop'
import Messaging from './components/messaging/Messaging'

import { fetchAndHandleIsUserAuthed } from './redux/modules/users'
import { fetchAndHandleTenant } from './redux/modules/tenant'
import SpaceContentRedirect from './components/experiences/experience/articles/SpaceContentRedirect'

const Authenticate = Async.Loadable({
  loader: () => import(/* webpackChunkName: "authenticate" */ './components/authenticate/Authenticate')
})
const Logout = Async.Loadable({
  loader: () => import(/* webpackChunkName: "logout" */ './components/logout/Logout')
})
const ForgotPassword = Async.Loadable({
  loader: () => import(/* webpackChunkName: "forgot-password" */ './components/authenticate/ForgotPassword')
})
const ResetPasswordContainer = Async.Loadable({
  loader: () => import(/* webpackChunkName: "reset-password-container" */ './legacy/containers/Password/ResetPasswordContainer')
})
const InvalidatePasswordResetContainer = Async.Loadable({
  loader: () => import(/* webpackChunkName: "invalid-password-reset-container" */ './legacy/containers/Password/InvalidatePasswordResetContainer')
})
const Join = Async.Loadable({
  loader: () => import(/* webpackChunkName: "join" */ './components/join/Join')
})
const Welcome = Async.Loadable({
  loader: () => import(/* webpackChunkName: "welcome" */ './components/welcome/Welcome')
})

const AssetPicker = Async.Loadable({
  loader: () => import(/* webpackChunkName: "asset-picker" */ './components/editor/AssetPicker')
})
const Experience = Async.Loadable({
  loader: () => import(/* webpackChunkName: "experience" */ './components/experiences/experience/Experience')
})
const ExperienceRootRedirect = Async.Loadable({
  loader: () => import(/* webpackChunkName: "experiences" */ './components/experiences/experience/ExperienceRootRedirect')
})
const Experiences = Async.Loadable({
  loader: () => import(/* webpackChunkName: "experiences" */ './components/experiences/Experiences')
})
const ExperiencesNew = Async.Loadable({
  loader: () => import(/* webpackChunkName: "experience-new" */ './components/experiences/ExperiencesNew')
})
const Home = Async.Loadable({
  loader: () => import(/* webpackChunkName: "experiences" */ './components/Home')
})

const UserDirectory = Async.Loadable({
  loader: () => import(/* webpackChunkName: "user-directory" */ './components/users/directory/UserDirectory')
})
const UserProfile = Async.Loadable({
  loader: () => import(/* webpackChunkName: "user-profile" */ './components/users/profile/UserProfile')
})
const DesignStore = Async.Loadable({
  loader: () => import(/* webpackChunkName: "design-store" */ './components/design/DesignStore')
})
const Administration = Async.Loadable({
  loader: () => import(/* webpackChunkName: "admin" */ './components/admin/Administration')
})
const Onboarding = Async.Loadable({
  loader: () => import(/* webpackChunkName: "onboarding" */ './components/onboarding/Onboarding')
})

const LicenseReportContainer = Async.Loadable({
  loader: () => import(/* webpackChunkName: "license-report" */ './legacy/containers/about/LicenseReportContainer')
})

const Register = Async.Loadable({
  loader: () => import(/* webpackChunkName: "register" */ './register/Register')
})
const RegisterComplete = Async.Loadable({
  loader: () => import(/* webpackChunkName: "register-complete" */ './register/RegisterComplete')
})
const ZoomLeave = Async.Loadable({
  loader: () => import(/* webpackChunkName: "zoom-leave" */ './components/meeting/zoom/ZoomLeave')
})
const Events = Async.Loadable({
  loader: () => import(/* webpackChunkName: "events" */ './components/events/Events')
})
const Unsubscribe = Async.Loadable({
  loader: () => import(/* webpackChunkName: "unsubscribe" */ './components/subscription/Unsubscribe')
})

const history = createBrowserHistory()

/**
 * Main application entry point, contains all the main high level route definitions.
 */
const AlohaApp = () => {
  const dispatch = useDispatch()
  const tenant = useSelector(state => state.tenant)
  const isAuthed = useSelector(state => state.users.isAuthed)
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    fetchAndHandleTenant(dispatch)
      .then(() => {
        fetchAndHandleIsUserAuthed(dispatch)
          .then(() => {
            setIsFetching(false)
          })
      })
      .catch(() => {
        setIsFetching(false)
      })
  }, [dispatch])

  useEffect(() => {
    if (tenant.info.properties) {
      const gaTrackingId = tenant.info.properties.gaTrackingId
      if (gaTrackingId) {
        const gaTrackingId = tenant.info.properties.gaTrackingId
        // initialize GA with tenant specific GA Tracking ID
        ReactGA.initialize(gaTrackingId)
        // we need to manually record page view here as the 1st page load does not trigger history.listen
        ReactGA.set({ page: history.location.pathname })
        ReactGA.pageview(history.location.pathname)
        // now we listen for route changes
        history.listen(location => {
          ReactGA.set({ page: location.pathname }) // Update the user's current page
          ReactGA.pageview(location.pathname) // Record a page view for the given page
        })
      } else {
        ReactGA.ga('remove')
      }
    }
  }, [tenant])

  const getFavicon = () => {
    if (tenant.info && tenant.info.branding && !isEmpty(tenant.info.branding.favicon)) {
      return <Favicon url={tenant.info.branding.favicon} />
    } else {
      // https://stackoverflow.com/questions/1321878/how-to-prevent-favicon-ico-requests
      return <Favicon url="data:," />
      // return null
    }
  }

  // `withRouter` allows to add `props.location.key` so that the component is re-rendered every time a request parameter is changed
  // const SearchContainerRoute = withRouter(props => <PrivateRoute path="/search" component={SearchContainer} key={props.location.key} />)

  return (
    // wait to get the user details before doing anything
    !isFetching && (
      <Router history={history}>
        <ScrollToTop>
          {getFavicon()}
          <Notifications />
          <div className="AlohaApp d-flex flex-row">
            <Switch>
              <PrivateRoute path="/editor/plugins/assetmanager/:spaceId/:entityId/:fileType/render" component={AssetPicker} isAuthed={isAuthed} />

              <Route path="/subscription/unsubscribe" component={Unsubscribe} />

              {/* user onboarding */}
              <PrivateRoute path="/onboarding" component={Onboarding} />

              {/* login/logout */}
              <Route path="/auth" component={Authenticate} />
              <Route path="/logout" component={Logout} />

              <Route exact path="/register/complete/:token" component={RegisterComplete} isAuthed={false} />

              {/* SPJAMB Register */}
              <Route exact path="/register" component={Register} />

              {/* password resets */}
              <Route path="/forgotpassword" component={ForgotPassword} />
              <Route path="/resetpassword" component={ResetPasswordContainer} />
              <Route path="/invalidatepasswordreset" component={InvalidatePasswordResetContainer} />

              {/* sign ups */}
              <Route path="/join" component={Join} isAuthed={false} />

              {/* new tenant admin welcome */}
              <Route path="/welcome" component={Welcome} isAuthed={false} />

              <AnonymousRoute path="/users/:userId" component={UserProfile} access="profile" />
              <PrivateRoute path="/users" component={UserDirectory} />

              {/* administration */}
              <PrivateRoute path="/admin" component={Administration} />

              {/** because it's a Switch, the path is required in `SearchContainerRoute` below: */}
              {/*
              <SearchContainerRoute path="/search" />
              */}

              <PrivateRoute path="/design" component={DesignStore} />

              {/** Sample layouts  */}
              <PrivateRoute path={`${Constants.EXPERIENCES_BASE_URL}/create`} component={ExperiencesNew} />

              {/* Experience Root URL - send a redirect */}
              <AnonymousRoute path={`${Constants.EXPERIENCES_BASE_URL}/:experienceKey`} exact component={ExperienceRootRedirect} />

              {/* Allow the :pageId to be passed into the ExperienceHeader */}
              <AnonymousRoute path={`${Constants.EXPERIENCES_BASE_URL}/:experienceKey/pages/:pageId`} component={Experience} />
              {/* All other experience routes that are not `Page` related */}
              <AnonymousRoute path={`${Constants.EXPERIENCES_BASE_URL}/:experienceKey`} component={Experience} />

              <AnonymousRoute path={Constants.EXPERIENCES_BASE_URL} exact component={Experiences} />

              <PrivateRoute path="/events" component={Events} />

              <AnonymousRoute exact path="/" component={RootContainer} />
              <PrivateRoute exact path="/home" component={Home} />
              <PrivateRoute exact path="/bookmarks" component={Home} />
              <PrivateRoute path="/spaces/:spaceId/contents/:contentId" component={SpaceContentRedirect} />

              <PrivateRoute path="/about/licenses" component={LicenseReportContainer} className="fullPage" />
              {/*
              <PrivateRoute path="/about/version" component={VersionContainer} className="fullPage" />
              */}
              <PrivateRoute path="/zoom/leave" component={ZoomLeave} className="fullPage" />

              <Route component={Status404} />
            </Switch>
          </div>
          <Messaging />
        </ScrollToTop>
      </Router>
    )
  )
}

export default AlohaApp
