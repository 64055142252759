import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Element } from 'react-scroll'

import { setPalette } from '../../common/utils/Theming'
import MenuDrawer from './menus/MenuDrawer'
import SearchDrawer from './search/SearchDrawer'
import MainHeader from './MainHeader'
import MainFooter from './MainFooter'
import Async from '../../common/utils/Async'
import { SilentLoader } from '../../legacy/components'
import LeftNav from './LeftNav'

import './colors.css'
import './MainLayout.css'

const CreateContentModal = Async.Loadable({
  loading: SilentLoader,
  loader: () => import(/* webpackChunkName: "create-content-modal" */ './create/CreateContentModal')
})

// example palette for Mahalo
const palette = [
  [27, 123, 172], // primary
  [41, 171, 165], // secondary
  [36, 152, 165], // tertiary
  [12, 87, 178] // quaternary
]

const MainLayout = ({ className = '', children }) => {
  // react hooks
  const authedId = useSelector(state => state.users.authedId)
  const tenant = useSelector(state => state.tenant)

  // for palette colors
  useEffect(() => {
    setPalette(palette)
  }, [])

  // setting whether the search drawer is open here, so that it can be passed to
  // children `SearchDrawer` and `MainHeader`, otherwise it would need to be stored in redux
  const [searchIsDrawerOpen, setSearchIsDrawerOpen] = useState(false)

  // setting whether the menu drawer is open here, so that it can be passed to
  // children `MenuDrawer` and `MainHeader`, otherwise it would need to be stored in redux
  const [menuIsDrawerOpen, setMenuIsDrawerOpen] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [contentType, setContentType] = useState(null)
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const selectContentType = (event, contentType) => {
    if (event) {
      event.preventDefault()
    }
    setIsModalOpen(true)
    setContentType(contentType)
  }
  return (
    <>
      {(authedId) && (
        <>
          <MenuDrawer isOpen={menuIsDrawerOpen} handleState={setMenuIsDrawerOpen} />
          <SearchDrawer isOpen={searchIsDrawerOpen} handleState={setSearchIsDrawerOpen} />
          <CreateContentModal isModalOpen={isModalOpen} contentType={contentType} toggleModal={toggleModal} />
        </>
      )}

      {/** .flex-grow-1 allows the layout to gro past the bottom of the screen */}
      <div className={`layout flex-grow-1 ${searchIsDrawerOpen ? '-no-overflow' : ''}`}>
        <Element name="_top" className="element" />
        <MainHeader handleSearchIsDrawerOpen={setSearchIsDrawerOpen} handleMenuIsDrawerOpen={setMenuIsDrawerOpen}
          menuIsDrawerOpen={menuIsDrawerOpen} selectContentType={selectContentType} />
        <div style={{ display: 'flex' }}>
          {(tenant.info.id === '59a5fc1cea9f02084235faf0' || tenant.info.id === '5e69bb4fb82af5000fbcd50c') && (
            <LeftNav />
          )}
          <main role="main">
            {/**
             `.flex-fill` - *important* - pushes the footer down below the bottom of the page if there is no more room
             `.d-flex.flex-column` children will have display: flex and be in columns
             */}
            <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
              <article className={`${className} flex-fill d-flex flex-column`}>{children}</article>
            </div>
          </main>
        </div>
        <MainFooter />
      </div>
    </>
  )
}

export default MainLayout
