// @see: https://medium.com/dailyjs/rewriting-javascript-converting-an-array-of-objects-to-an-object-ec579cafbfc7
export const arrayToObject = (array, keyField) => {
  return array ? array.reduce((obj, item) => {
    obj[item[keyField]] = item
    return obj
  }, {}) : {}
}

export const objectToArray = obj =>
  Object.keys(obj).map(function (key) {
    return obj[key]
  })

/**
 * arrayKeyValueToObject(
 *   [ { name: 'a', value:'A' }, { name: 'b', value:'B' }, { name: 'c', value:'C' }  ],
 *   'name',
 *   'value'
 * )
 * --> { a: 'A', b: 'B', c: 'C' }
 */
export const arrayKeyValueToObject = (array, keyField, valueField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item[valueField]
    return obj
  }, {})

/**
 * From https://stackoverflow.com/a/5306832/1958200 "fancier version", but without manipulating the prototype
 *
 * @param {*} arr
 * @param {*} oldIndex
 * @param {*} newIndex
 */
export const moveItemInArray = (arr, oldIndex, newIndex) => {
  while (oldIndex < 0) {
    oldIndex += arr.length
  }
  while (newIndex < 0) {
    newIndex += arr.length
  }
  if (newIndex >= arr.length) {
    var k = newIndex - arr.length
    while (k-- + 1) {
      arr.push(undefined)
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
  return arr
}

/**
 * Generic array sorting
 * https://davidsimpson.me/2014/05/22/how-to-sort-an-array-of-json-arrays/
 *
 * @param property
 * @returns {Function}
 */
export const sortByProperty = property => {
  return (x, y) => {
    return x[property] === y[property] ? 0 : x[property] > y[property] ? 1 : -1
  }
}
