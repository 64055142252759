import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

// import the root reducer
import rootReducer from './rootReducer'
// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-redux#usage
// Create a history of your choosing (we're using a browser history in this case)
// export const browserHistory = createHistory()

// Build the middleware for intercepting and dispatching navigation actions
// const routerMiddleware = routerMiddleware(browserHistory)

const composed = window.__REDUX_DEVTOOLS_EXTENSION__
  ? compose(
    applyMiddleware(thunk),
    // applyMiddleware( routerMiddleware(browserHistory) )
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
  : compose(
    applyMiddleware(thunk)
    // applyMiddleware( routerMiddleware(browserHistory) )
  )

export const store = createStore(rootReducer, composed)
