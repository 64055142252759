import Permissions from './Permissions'

export const EDITOR_TYPE_COMMENT = 'comment'

export const DEFAULT_ROW_PADDING_TOP = '2em'
export const DEFAULT_ROW_PADDING_BOTTOM = '2em'
export const DEFAULT_ROW_PADDING_LEFT = '6em'
export const DEFAULT_ROW_PADDING_RIGHT = '6em'

export const EXPERIENCE_MEMBERSHIP_MEMBER = 'member'
export const EXPERIENCE_MEMBERSHIP_OWNER = 'owner'
export const EXPERIENCE_MEMBERSHIP_EDITOR = 'editor'

export const ADMIN_APPS_STATUSES_INSTALLED = {
  label: 'Installed Apps',
  value: 'installed'
}
export const ADMIN_APPS_STATUSES_AVAILABLE = {
  label: 'Available Apps',
  value: 'available'
}

export const STATUS_DRAFT = 'DRAFT'
export const STATUS_PUBLISHED = 'PUBLISHED'
export const STATUS_TRASHED = 'TRASHED'
// export const TENANT_MODE_PUBLIC = 'public'

export const CONTENT_STATUS_DRAFT = 'DRAFT'

export const UNKNOWN_USER_FULLNAME = 'Anonymous User'

export const EXPERIENCE_ACCESS_LEVEL_PUBLIC = 'PUBLIC'
export const EXPERIENCE_ACCESS_LEVEL_PRIVATE = 'PRIVATE'

export const ERROR_EXPERIENCE_UNAUTHORIZED = 'error.experience.unauthorized'
export const ERROR_EXPERIENCE_FETCHING = 'error.experience.fetching'

export const APP_DISPLAY_CARD = 'card'
export const APP_DISPLAY_LIST = 'list'

export const APP_ORDER_ASCENDING = 'asc'
export const APP_ORDER_DESCENDING = 'desc'

export const CALENDAR_DISPLAY_DAY_GRID_MONTH = 'dayGridMonth'
export const CALENDAR_DISPLAY_LIST_MONTH = 'listMonth'

export const REGISTRATION_STATUS_PENDING = 'PENDING'
export const REGISTRATION_STATUS_APPROVED = 'APPROVED'
export const REGISTRATION_STATUS_DENIED = 'DENIED'

export const EVENT_TYPE_LOCAL = 'LOCAL'
export const EVENT_TYPE_ONLINE = 'ONLINE'
export const EVENT_RSVP_YES = 'yes'
export const EVENT_RSVP_NO = 'no'
export const EVENT_RSVP_MAYBE = 'maybe'

export const EVENT_PRICING_FREE = 'FREE'
export const EVENT_PRICING_PAID = 'PAID'

export const EXPERIENCES_BASE_URL = '/experiences'

export const INVITE_OPTION_EXPERIENCE = 'experience'
export const INVITE_OPTION_GROUP = 'group'
export const INVITE_OPTION_USER = 'user'
export const INVITE_OPTION_UPLOAD = 'upload'

export const PAYMENT_GATEWAY_SQUARE_ENVIRONMENT_SANDBOX = 'sandbox'
export const PAYMENT_GATEWAY_SQUARE_ENVIRONMENT_PRODUCTION = 'production'

export const ENTITY_TYPE_EVENT = 'event'
export const ENTITY_TYPE_CONTENT = 'content'

// export const EXPERIENCE_TYPE_GLOBAL = 'global'
// export const EXPERIENCE_TYPE_PERSONAL = 'personal'

export const MENU_ACCESS_ALL = 'all'
export const MENU_ACCESS_AUTHED = 'authed'
export const MENU_ACCESS_ANON = 'anon'

export const ANONYMOUS_ACCESS_PROFILE = 'profile'

export const VIEW_MODE_CARD = 'card'
export const VIEW_MODE_TABLE = 'table'

// Announcement constants
export const ANNOUNCEMENT_TYPE_SCRIPT = 'script'
export const ANNOUNCEMENT_TYPE_DESIGN = 'design'
export const BG_TYPE_COLOR = 'bg-color'
export const BG_TYPE_IMAGE = 'bg-img'

export default {
  EXPERIENCES_BASE_URL: '/experiences',
  CONTENT_ADMIN_PAGINATION_DEFAULT_SIZE: 10,
  // should have factor which correspond to the number of columns in the responsive design, i.e. 1,2,3,4
  PAGINATION_SIZE_GRID_LAYOUT_OPTIMIZED: 24,
  INVITATION_STATUS_ACCEPTED: 'ACCEPTED',
  alerts: {
    DISMISS_TIMEOUT: 10000 // Constants.alerts.DISMISS_TIMEOUT in ms
  },
  branding: {
    background: '#85ce36',
    foreground: '#fff'
  },
  permissions: [
    /*
    {
      name: 'BI Administrator',
      value: Permissions.PERMISSION_BI_ADMIN
    }, */
    {
      name: 'BI Designer',
      value: Permissions.PERMISSION_BI_DESIGNER
    },
    {
      name: 'Content Author',
      value: Permissions.PERMISSION_CONTENT_AUTHOR
    },
    {
      name: 'Experience Creator',
      value: Permissions.PERMISSION_SPACE_CREATOR
    },
    {
      name: 'Organization Administrator',
      value: Permissions.PERMISSION_ORG_ADMIN
    }
  ],
  languages: [
    {
      id: 'cs-CZ',
      label: 'Čeština'
    },
    {
      id: 'cy-GB',
      label: 'Cymraeg'
    },
    {
      id: 'de-DE',
      label: 'Deutsch'
    },
    {
      id: 'en-GB',
      label: 'English (UK)'
    },
    {
      id: 'en-US',
      label: 'English (US)'
    },
    {
      id: 'es-ES',
      label: 'Español'
    },
    {
      id: 'fr-FR',
      label: 'Français'
    },
    {
      id: 'hi-IN',
      label: 'हिंदी'
    },
    {
      id: 'it-IT',
      label: 'Italiano'
    },
    {
      id: 'ja-JP',
      label: '日本語'
    },
    {
      id: 'nl-NL',
      label: 'Nederlands'
    },
    {
      id: 'zh-CN',
      label: '中文'
    }
  ],
  stream: {
    source: {
      rss: 'Feed'
    }
  },
  undraw: {
    primaryColor: '#007bff'
  }
}
