import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import isEmpty from 'lodash/isEmpty'

/**
 * A generic 403 page.
 */
const Status403 = ({ message }) => {
  const { t } = useTranslation()
  const tenant = useSelector(state => state.tenant.info)
  return (
    <>
      <Helmet defer={false}>
        <title>Permission Denied - {tenant.name}</title>
      </Helmet>
      <div className="error-title-block text-center mb-5 mt-5">
        <img src="/images/undraw/undraw_secure_data.svg" alt="no_permission" style={{ height: '30vh' }} />
        <div className="pt-4">
          {isEmpty(message) ? (
            t('errors.forbidden.description')
          ) : (
            message
          )}
        </div>
      </div>
    </>
  )
}

Status403.propTypes = {
  message: PropTypes.string
}

export default Status403
