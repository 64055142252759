import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Icon } from 'react-fa'
import isEmpty from 'lodash/isEmpty'
import messagingApi from '../../common/apis/MessagingApi'

import './Messaging.css'

const Messaging = () => {
  const tenant = useSelector(state => state.tenant)
  const currentUser = useSelector(state => state.users[state.users.authedId])

  const serviceUrl = (tenant.isFetching === false && tenant && tenant.info && tenant.info.properties && tenant.info.properties['messagingUrl']) || null

  // setup local state
  const [isOpen, setIsOpen] = useState(false)
  const [presenceStatus, setPresenceStatus] = useState('offline')

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const messageEventHandler = e => {
      if (e.data.eventName === 'status-changed') {
        // presence status change event
        const newStatus = e.data.data
        setPresenceStatus(newStatus)

        let elements = document.querySelectorAll('.presence-self')
        for (let index = 0; index < elements.length; index++) {
          const classList = elements[index].classList
          classList.remove('presence-away', 'presence-online', 'presence-offline', 'presence-busy')
          classList.add(`presence-${newStatus}`)
        }
      } else if (e.data.eventName === 'startup' && e.data.data === true) {
        // ALOHA-2488 - workaround to trigger a logout call and then log in to make sure the correct user is signed in.
        document.querySelector('iframe.am-frame').contentWindow.postMessage(
          {
            externalCommand: 'logout'
          },
          '*'
        )

        // default to the home page
        document.querySelector('iframe.am-frame').contentWindow.postMessage(
          {
            externalCommand: 'go',
            path: '/home'
          },
          '*'
        )

        messagingApi
          .getUserPresence(currentUser.info.id)
          .then(response => {
            if (response.data && response.data.presence) {
              const newStatus = response.data.presence
              setPresenceStatus(newStatus)
              let elements = document.querySelectorAll('.presence-self')
              for (let index = 0; index < elements.length; index++) {
                const classList = elements[index].classList
                classList.remove('presence-away', 'presence-online', 'presence-offline', 'presence-busy')
                classList.add(`presence-${newStatus}`)
              }
            }
          })
          .catch(error => console.log('messagingApi.getUserPresence error ', error))
      }
    }

    if (!isEmpty(currentUser) && !isEmpty(serviceUrl)) {
      window.addEventListener('message', messageEventHandler)
    }

    return () => {
      window.removeEventListener('message', messageEventHandler)
    }
  }, [currentUser, serviceUrl])

  return (
    window.top === window.self &&
    (!isEmpty(currentUser) && !isEmpty(serviceUrl) && (
      <div className="messaging-outer _____hidden">
        <div id="messagingAccordion" role="tablist" aria-multiselectable="true" className="m-0 p-0">
          <div className="card m-0 p-0">
            <div className="card-header m-0 p-0" role="tab" id="headingMessaging" data-toggle="collapse" data-parent="#messagingAccordion" data-target="#collapseMessaging">
              <h5 className="m-0 p-0">
                <button data-toggle="collapse" data-parent="#messagingAccordion" className={'btn messaging-trigger ' + (isOpen ? 'opened' : 'closed')} onClick={toggleOpen}>
                  <div className={'presence-indicator presence-' + presenceStatus} />
                  Messaging
                  <Icon name="chevron-up float-right text-muted" />
                </button>
              </h5>
            </div>
            <div id="collapseMessaging" className="collapse" role="tabpanel" aria-labelledby="headingMessaging">
              <div className="card-block p-0">
                <iframe title="am-frame" className="am-frame" frameBorder="0" height="500px" width="395px" src={serviceUrl} allow="geolocation; microphone; camera" />
              </div>
            </div>
          </div>
        </div>
      </div>
    ))
  )
}

export default Messaging
