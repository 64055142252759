import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ANONYMOUS_ACCESS_PROFILE } from '../../common/utils/Constants'

/**
 * A special kind of Route that allows for anonymous access, unlike the PrivateRoute.
 */
export default function AnonymousRoute ({ component: Component, ...rest }) {
  // get users data from Redux store
  const currentUser = useSelector(state => state.users[state.users.authedId])
  const tenant = useSelector(state => state.tenant)

  /**
   * Check to see if the user is onboarded.
   *
   * @returns {*|boolean} true if user is onboarded, false if not.
   */
  const isOnboarded = () => {
    const isUserOnboarded = currentUser.info.properties.isOnboarded
    return isUserOnboarded && (isUserOnboarded === 'true' || isUserOnboarded === true)
  }

  const redirectUrl = () => {
    const redirectTo = window.location.pathname + window.location.search
    let to = '/auth'
    if (redirectTo && redirectTo !== '/') {
      to = `${to}?redirectTo=${encodeURIComponent(redirectTo)}`
    }
    return to
  }

  return (
    currentUser ? (
      isOnboarded() !== true && rest.path !== '/onboarding' ? (
        <Redirect to={`/onboarding?redirectTo=${encodeURIComponent(window.location.pathname + window.location.search)}`} />
      ) : (
        <Route
          {...rest}
          render={props => {
            return <Component {...props} />
          }}
        />
      )
    ) : (
      rest.access === ANONYMOUS_ACCESS_PROFILE ? (
        tenant.info.security && tenant.info.security.allowAnonymousProfile ? (
          <Route
            {...rest}
            render={props => {
              return <Component {...props} />
            }}
          />
        ) : (
          <Redirect to={redirectUrl()} />
        )
      ) : (
        tenant.info.security && tenant.info.security.allowAnonymous ? (
          <Route
            {...rest}
            render={props => {
              return <Component {...props} />
            }}
          />
        ) : (
          <Redirect to={redirectUrl()} />
        )
      )
    )
  )
}

AnonymousRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]).isRequired
}
