import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

/**
 * Custom route component to ensure the user is authenticated before letting the user access the route.
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  // get users data from Redux store
  const users = useSelector(state => state.users)

  /**
   * Check to see if the user is authenticated.
   *
   * @returns {any|boolean} true if user is authenticated, false if not.
   */
  const isAuthed = () => {
    return users && users.authedId !== ''
  }

  /**
   * Check to see if the user is onboarded.
   *
   * @returns {*|boolean} true if user is onboarded, false if not.
   */
  const isOnboarded = () => {
    const isUserOnboarded = users[users.authedId].info.properties.isOnboarded
    return isUserOnboarded && (isUserOnboarded === 'true' || isUserOnboarded === true)
  }
  return (
    <Route
      {...rest}
      render={props => {
        const redirectTo = props.location.pathname + props.location.search
        // const to = `/auth?redirectTo=${encodeURIComponent(props.location.pathname + props.location.search)}`
        let to = '/auth'
        if (redirectTo && redirectTo !== '/') {
          to = `${to}?redirectTo=${encodeURIComponent(redirectTo)}`
        }
        return isAuthed() === true ? (
          isOnboarded() !== true && rest.path !== '/onboarding' ? (
            <Redirect to={`/onboarding?redirectTo=${encodeURIComponent(redirectTo)}`} />
          ) : (
            <Component {...props} {...rest} />
          )
        ) : (
          <Redirect to={to} />
        )
      }}
    />
  )
}
export default PrivateRoute
