import React from 'react'
import PropTypes from 'prop-types'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

import { slugifyString } from '../../common/utils/UrlUtils'

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

/**
 * Component to render a search result.
 */
const SearchResult = ({ item }) => {
  const formatDate = (item) => {
    const when = new Date(item)
    const options = { month: 'short', day: 'numeric', year: 'numeric' }
    return when.toLocaleString(window.navigator.language, options)
  }

  return (
    <div className="list-group-item stream-item flex-column align-items-start pl-0 pr-0">
      <a href={`${item.link}/${slugifyString(item.title)}`} className="search-result-link p-0" title={item.title}>
        <h3 className="h5 p-0">
          <img src={`/images/icons/posts/${item.type}.svg`} alt={item.type} height="16px" width="16px" className="mr-2" />
          {item.title.length > 100 ? item.title.substring(0, 100) + '…' : item.title}
        </h3>
      </a>

      <div className="mb-1">
        <ResponsiveEllipsis
          unsafeHTML={item.body}
          maxLine={3}
          ellipsis='...'
          basedOn='letters'
        />
      </div>

      <small className="text-muted">
        In {item.experience.name} • Updated on {formatDate(item.lastModified)}
      </small>
    </div>
  )
}

SearchResult.propTypes = {
  item: PropTypes.object.isRequired
}

export default SearchResult
