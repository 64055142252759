import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

// import reducers - only for "top level objects"
// import blueprints from './modules/blueprints'
import editing from './modules/editing'
// import editingTray from './modules/editingTray'
import experiences from './modules/experiences'
import notifications from './modules/notifications'
// import spaces from './modules/spaces'
import tenant from './modules/tenant'
import users from './modules/users'

// combine the reducers
const rootReducer = combineReducers({
  // blueprints,
  notifications,
  editing,
  // editingTray,
  experiences,
  // spaces,
  tenant,
  users,

  /* other reducers here, comma separated, then the routing */
  routing: routerReducer
})

export default rootReducer
