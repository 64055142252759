export function getApiRoot () {
  const protocol = window.location.protocol
  const hostname = window.location.hostname

  if (hostname.includes('localhost')) {
    return `${protocol}//${hostname}:9000`
  } else {
    return `${protocol}//aloha-api.${getRootDomain(hostname)}`
  }
}

export function getFileServiceRoot () {
  const protocol = window.location.protocol
  const hostname = window.location.hostname

  if (hostname.includes('localhost')) {
    return `${protocol}//${hostname}:9000`
  } else {
    return `${protocol}//aloha-files.${getRootDomain(hostname)}`
  }
}

export function arrayMove (array, from, to) {
  array = array.slice()
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0])
  return array
}

function getRootDomain (hostname) {
  const hostnameParts = hostname.split('.')
  hostnameParts.shift()
  return hostnameParts.join('.')
}
