import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import EditingBlanket from '../editing/EditingBlanket'

import './NotAuthenticatedLayout.css'

const MainFooter = () => {
  const { experienceKey } = useParams()
  const experience = useSelector(state => state.experiences[experienceKey])
  const customFooter = experience?.info?.branding?.footer
  // check if we are on an experience setting page
  const match = useRouteMatch('/experiences/:experienceKey/settings')

  return (
    <div className="MainFooter">
      <EditingBlanket />
      <footer className="aloha-footer container px-md-5" style={{ background: (!match && customFooter) ? customFooter.bgColor : '#fff' }}>
        {(!match && customFooter && !isEmpty(customFooter.content)) && (
          <div className="pt-4">
            <div className="w-100" dangerouslySetInnerHTML={{ __html: customFooter.content }} />
          </div>
        )}
        <div className="py-4">
          <div className="col col-12 text-center">
            <div className="footer default-footer text-center text-sm">
              Made with <span role="img" aria-label="heart">&#10084;&#65039;</span> and <span role="img" aria-label="pineapple">&#x1F34D;</span> on the <a href="https://www.alohacloud.com" rel="nofollow">AlohaCloud platform</a>
              <a href="https://appfusions.zendesk.com" rel="nofollow">Report a problem</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
export default MainFooter
