import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, AlertContainer } from 'react-bs-notifier'

import { handleNotificationRemove } from '../../redux/modules/notifications'

import './Notifications.css'

const Notifications = () => {
  const dispatch = useDispatch()
  const notifications = useSelector(state => state.notifications)

  const dismiss = (e, item) => {
    handleNotificationRemove(item, dispatch)
  }

  return (
    <div className="NotificationsContainer">
      {notifications && notifications.items && (
        <AlertContainer position="bottom-left" className="Notifications">
          {notifications.items.map((item, index) => (
            <Alert key={index} type={item.type} onDismiss={e => dismiss(e, item)} showIcon={false}>
              {item.message}
            </Alert>
          ))}
        </AlertContainer>
      )}
    </div>
  )
}

export default Notifications
