import { contrastColor } from './Colors'

export function setPalette (palette) {
  if (palette.length > 0) {
    ;['primary', 'secondary', 'tertiary', 'quaternary'].map((item, i) => {
      document.documentElement.style.setProperty(`--palette-color-${item}`, `rgb(${palette[i][0]} ${palette[i][1]} ${palette[i][2]})`)
      document.documentElement.style.setProperty(`--palette-color-${item}-contrast`, contrastColor(palette[i]))
      return null
    })
  }
}
