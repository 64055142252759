import axios from 'axios'
import { getApiRoot } from '../utils/SystemUtils'

export default {
  updatePanelConfig (experienceId, pageId, panelId, config) {
    const payload = {
      config: config
    }
    return axios
      .post(`${getApiRoot()}/api/spaces/${experienceId}/zones/${pageId}/panels/${panelId}/config`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  updatePanelSettings (experienceId, pageId, panelId, settings) {
    return axios
      .post(`${getApiRoot()}/api/spaces/${experienceId}/zones/${pageId}/panels/${panelId}/settings`, { settings: settings })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  }
}
