import React from 'react'
import { useSelector } from 'react-redux'

const EditingBlanket = ({ children }) => {
  const editing = useSelector(state => state.editing)
  return editing && editing.isActive ? (
    <>
      {children}
      <div className="blanket EditingBlanket"></div>
    </>
  ) : null
}

export default EditingBlanket
