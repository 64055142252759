import axios from 'axios'
import { getApiRoot } from '../utils/SystemUtils'

export function setTenantProperty (key, value) {
  const data = {
    key: key,
    value: value
  }
  return axios
    .post(`${getApiRoot()}/api/admin/tenant/properties`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export function removeTenantProperty (key) {
  const data = {
    key: key,
    value: null
  }
  return axios
    .post(`${getApiRoot()}/api/admin/tenant/properties`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export default {
  updateOrgSettings (tenantId, settings) {
    return axios
      .post(`${getApiRoot()}/api/tenants/${tenantId}/settings`, settings)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  updateTenantLogo (logo, tenantId) {
    const payload = {
      logo: logo
    }

    return axios
      .post(`${getApiRoot()}/api/tenants/${tenantId}/logo`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  updateTenantFavicon (favicon, tenantId) {
    const payload = {
      favicon: favicon
    }

    return axios
      .post(`${getApiRoot()}/api/tenants/${tenantId}/favicon`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  }

}
