import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import AlohaApp from './AlohaApp'

// polyfills
import './polyfills'

// redux store
import { store } from './redux/store'

// service worker
import * as serviceWorker from './serviceWorker'

// i18n
import './i18n'

// css
import './styles/bootstrap/index.css'
import './index.css'
import 'tinymce/skins/ui/oxide/content.min.css'

const Loader = () => <div style={{ margin: 10 }}>...</div>

ReactDOM.render(
  <Provider store={store}>
    {/** Suspense wrapper to prevent i18n from failing in child components :(  */}
    <Suspense fallback={<Loader />}>
      <AlohaApp />
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
