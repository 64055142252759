import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
// import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import LanguageDetector from 'i18next-browser-languagedetector'
// @see: https://react.i18next.com/guides/quick-start

/*
const lngDetector = new I18nextBrowserLanguageDetector()
lngDetector.addDetector({
  name: 'customDetector',
  init: function (services, detectorOptions, i18nextOptions) {
  },
  detect: function (callback) { // You'll receive a callback if you passed async true
    // callback('de'); if you used the async flag
    console.log('sss')
    return 'fr-FR'
  },
  cacheUserLanguage (lng, options) {
    if (lng.substring(0, 2) === 'en') { localStorage.setItem('i18nextLng', 'en_gb') } else { localStorage.setItem('i18nextLng', 'de_de') }
  },
  lookup (options) {
    console.log(options)
    return 'fr-FR'
  }
})
*/

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
      // format: (value, format, lng) => { // legacy usage
      //   if (value instanceof Date) {
      //     return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format])
      //   }
      //   return value;
      // }
    },
    detection: {
      order: ['cookie', 'querystring', 'localStorage', 'navigator'],
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'i18nextLng'
    }
  })
/*
i18n
  .use(initReactI18next)
  .use(Backend)
  // .use(LanguageDetector)
  .init({
    fallbackLng: 'en',

    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'i18nextLng'
    },

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    debug: window.location.hostname === 'localhost', // debug on locahost only

    interpolation: {
      escapeValue: false // React already does escaping
    },

    react: {
      wait: true
    }
  })
*/

export default i18n
