import axios from 'axios'
import { getApiRoot } from '../utils/SystemUtils'

export function search (query, page, pageSize = 10) {
  const payload = {
    query: query
  }

  return axios
    .post(`${getApiRoot()}/api/search?start=${page}&limit=${pageSize}`, payload)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}
