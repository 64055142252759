import React from 'react'

import Pagination from 'rc-pagination'

import './BootstrapPagination.css'

/**
 * This is a lightweight method of wrapping the rc-pagination library so that it
 * gets as close as possible to Bootstrap Pagination as see here:
 * https://getbootstrap.com/docs/4.3/components/pagination/
 *
 * Some things are a hack and are not quite correct - look at `BootstrapPagination.css` for details.
 *
 * - the root element receives the `prefixCls` class, so we have to reset some selectors
 * - some classes are incorrectly named, so require more CSS to fix this
 */

const BootstrapPagination = ({ current, total, pageSize, onChange, locale = {}, showTitle = false, isSuppressHash = false }) => (
  <Pagination
    className="pagination"
    prefixCls="page-item "
    itemRender={(current, type, element) => {
      if (type === 'page') {
        return (
          <a className="page-link" href={`#${current}`} onClick={e => isSuppressHash && e.preventDefault()}>
            {current}
          </a>
        )
      } else if (type === 'prev') {
        return (
          <a className="page-link" href={`#${current}`} onClick={e => isSuppressHash && e.preventDefault()}>
            <span aria-hidden="true">&laquo;</span>
          </a>
        )
      } else if (type === 'next') {
        return (
          <a className="page-link" href={`#${current}`} onClick={e => isSuppressHash && e.preventDefault()}>
            <span aria-hidden="true">&raquo;</span>
          </a>
        )
      } else if (type === 'jump-next' || type === 'jump-prev') {
        return (
          <a className="page-link" href={`#${current}`} onClick={e => isSuppressHash && e.preventDefault()}>
            <span aria-hidden="true">&#8943;</span>
          </a>
        )
      }
      return element
    }}
    current={current}
    total={total}
    pageSize={pageSize}
    onChange={onChange}
    locale={locale}
    showTitle={showTitle}
    showLessItems
  />
)

export default BootstrapPagination
