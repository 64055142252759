/**
 * A way to async load a component, but not show a loader
 * For overriding the default loader
 *
 * Usage:
 *
 *     const SpaceEditTrayContainer = Async.Loadable( {
 *       loading: SilentLoader,
 *       loader: () => import(/ * webpackChunkName: "space-edit" * / '../../containers/SpaceEditTray/SpaceEditTrayContainer')
 *     })
 */
export default function SilentLoader () {
  return null
}
