/**
 * Servicing the top level `users` object in the redux store
 */

import api from '../../common/apis/Api'
import profileApi from '../../common/apis/ProfileApi'
import favoritesApi from '../../common/apis/FavoritesApi'

import { arrayKeyValueToObject } from '../../common/utils/DataFormatting'
import i18n from '../../i18n'
import { handleNotificationAdd } from './notifications'

/**
 * Actions
 */
const AUTH_USER = 'AUTH_USER'
const UNAUTH_USER = 'UNAUTH_USER'

const FETCHING_USER = 'FETCHING_USER'
const FETCHING_USER_FAILURE = 'FETCHING_USER_FAILURE'
const FETCHING_USER_SUCCESS = 'FETCHING_USER_SUCCESS'

const FETCHING_IS_USER_AUTHENTICATED = 'FETCHING_IS_USER_AUTHENTICATED'
const FETCHING_IS_USER_AUTHENTICATED_FAILURE = 'FETCHING_IS_USER_AUTHENTICATED_FAILURE'
const FETCHING_IS_USER_AUTHENTICATED_SUCCESS = 'FETCHING_IS_USER_AUTHENTICATED_SUCCESS'

const FETCHING_UPDATE_USER_TENANT = 'FETCHING_UPDATE_USER_TENANT'
const FETCHING_UPDATE_USER_TENANT_FAILURE = 'FETCHING_UPDATE_USER_TENANT_FAILURE'
const FETCHING_UPDATE_USER_TENANT_SUCCESS = 'FETCHING_UPDATE_USER_TENANT_SUCCESS'

const FETCHING_UPDATE_DEFAULT_SPACE = 'FETCHING_UPDATE_DEFAULT_SPACE'
const FETCHING_UPDATE_DEFAULT_SPACE_FAILURE = 'FETCHING_UPDATE_DEFAULT_SPACE_FAILURE'
const FETCHING_UPDATE_DEFAULT_SPACE_SUCCESS = 'FETCHING_UPDATE_DEFAULT_SPACE_SUCCESS'

const FETCHING_UPDATE_USER_INFO = 'FETCHING_UPDATE_USER_INFO'
const FETCHING_UPDATE_USER_INFO_FAILURE = 'FETCHING_UPDATE_USER_INFO_FAILURE'
const FETCHING_UPDATE_USER_INFO_SUCCESS = 'FETCHING_UPDATE_USER_INFO_SUCCESS'

const FETCHING_UPDATE_USER_INFO_PROPERTIES = 'FETCHING_UPDATE_USER_INFO_PROPERTIES'
const FETCHING_UPDATE_USER_INFO_PROPERTIES_FAILURE = 'FETCHING_UPDATE_USER_INFO_PROPERTIES_FAILURE'
const FETCHING_UPDATE_USER_INFO_PROPERTIES_SUCCESS = 'FETCHING_UPDATE_USER_INFO_PROPERTIES_SUCCESS'

const FETCHING_UPDATE_USER_PROFILE_ATTRIBUTES = 'FETCHING_UPDATE_USER_PROFILE_ATTRIBUTES'
const FETCHING_UPDATE_USER_PROFILE_ATTRIBUTES_FAILURE = 'FETCHING_UPDATE_USER_PROFILE_ATTRIBUTES_FAILURE'
const FETCHING_UPDATE_USER_PROFILE_ATTRIBUTES_SUCCESS = 'FETCHING_UPDATE_USER_PROFILE_ATTRIBUTES_SUCCESS'

const FETCHING_UPDATE_USER_FAVORITES = 'FETCHING_UPDATE_USER_FAVORITES'
const FETCHING_UPDATE_USER_FAVORITES_FAILURE = 'FETCHING_UPDATE_USER_FAVORITES_FAILURE'
const FETCHING_UPDATE_USER_FAVORITES_SUCCESS = 'FETCHING_UPDATE_USER_FAVORITES_SUCCESS'

const REMOVE_EXPERIENCE_FROM_FAVORITES = 'REMOVE_EXPERIENCE_FROM_FAVORITES'

const UPDATE_USER_PERMISSIONS = 'UPDATE_USER_PERMISSIONS'

/**
 * Initial states for reducers
 */

const initialUserState = {
  isFetching: false,
  info: {
    name: '',
    uid: '',
    avatar: '',
    tenantId: ''
  }
}

const initialUsersState = {
  isAuthed: false,
  isFetching: true,
  error: '',
  authedId: ''
}

/**
 * Reducer for users.[user]
 */

export function user (state = initialUserState, action) {
  switch (action.type) {
    case FETCHING_IS_USER_AUTHENTICATED_SUCCESS:
    case FETCHING_USER_SUCCESS:
      const userSuccess = {
        ...state,
        info: action.user
      }
      userSuccess.info.properties.lastActive = Date.now()
      return userSuccess
    case FETCHING_UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        ...action.user
      }

    case FETCHING_UPDATE_USER_INFO_PROPERTIES_SUCCESS:
      const stateForUpdateUserProperties = {
        ...state,
        ...action.user
      }
      stateForUpdateUserProperties.info.properties = {
        ...stateForUpdateUserProperties.info.properties,
        ...action.properties
      }
      return stateForUpdateUserProperties

    case FETCHING_UPDATE_USER_FAVORITES_SUCCESS:
      const stateForUpdateUserFavorites = {
        ...state,
        ...action.user
      }
      stateForUpdateUserFavorites.info.favorites = {
        ...stateForUpdateUserFavorites.info.favorites,
        ...action.favorites
      }

      return stateForUpdateUserFavorites
    case REMOVE_EXPERIENCE_FROM_FAVORITES:
      const stateForRemoveExperienceFromFavorites = {
        ...state,
        ...action.user
      }

      const { favorites } = stateForRemoveExperienceFromFavorites.info

      const experienceIds = favorites.experienceIds.filter(item => item !== action.experience.id)
      const experiences = favorites.experiences.filter(item => item.id !== action.experience.id)

      stateForRemoveExperienceFromFavorites.info.favorites = {
        ...stateForRemoveExperienceFromFavorites.info.favorites,
        experienceIds,
        experiences
      }

      return stateForRemoveExperienceFromFavorites

    case FETCHING_UPDATE_USER_PROFILE_ATTRIBUTES_SUCCESS:
      const stateForUpdateUserProfileAttributes = {
        ...state,
        ...action.user
      }
      stateForUpdateUserProfileAttributes.info.profileAttributes = {
        ...stateForUpdateUserProfileAttributes.info.profileAttributes,
        ...action.attributes
      }
      return stateForUpdateUserProfileAttributes

    case FETCHING_UPDATE_USER_TENANT_SUCCESS:
      const stateForTenantUpdate = {
        ...state
      }
      stateForTenantUpdate.info.tenant = action.tenant
      return stateForTenantUpdate
    case FETCHING_UPDATE_DEFAULT_SPACE_SUCCESS:
      const stateForUpdateDefaultSpace = {
        ...state
      }
      stateForUpdateDefaultSpace.info.preference.defaultSpace = action.defaultSpace
      return stateForUpdateDefaultSpace
    case UPDATE_USER_PERMISSIONS:
      const newState = {
        ...state,
        ...action.user
      }
      newState.info.permissions = action.permissions
      return newState
    case FETCHING_UPDATE_DEFAULT_SPACE:
    case FETCHING_UPDATE_DEFAULT_SPACE_FAILURE:
    default:
      return state
  }
}

/**
 * Reducer for users
 */
export default function users (state = initialUsersState, action) {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        isAuthed: true,
        authedId: action.uid
      }
    case UNAUTH_USER:
      return {
        isAuthed: false,
        isFetching: false,
        error: '',
        authedId: ''
      }

    case FETCHING_IS_USER_AUTHENTICATED:
    case FETCHING_USER:
      return {
        ...state,
        isFetching: true
      }

    case FETCHING_IS_USER_AUTHENTICATED_FAILURE:
    case FETCHING_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }

    case FETCHING_IS_USER_AUTHENTICATED_SUCCESS:
    case FETCHING_USER_SUCCESS:
      return action.user === null
        ? {
          ...state,
          isAuthed: true,
          isFetching: false,
          error: ''
        }
        : {
          ...state,
          isFetching: false,
          error: '',
          [action.uid]: user(state[action.uid], action)
        }

    case FETCHING_UPDATE_USER_INFO:
    case FETCHING_UPDATE_USER_INFO_PROPERTIES:
    case FETCHING_UPDATE_USER_PROFILE_ATTRIBUTES:
    case FETCHING_UPDATE_USER_TENANT:
      return {
        ...state,
        isFetching: true
      }
    case FETCHING_UPDATE_USER_INFO_FAILURE:
    case FETCHING_UPDATE_USER_INFO_PROPERTIES_FAILURE:
    case FETCHING_UPDATE_USER_PROFILE_ATTRIBUTES_FAILURE:
    case FETCHING_UPDATE_USER_TENANT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    case FETCHING_UPDATE_USER_INFO_PROPERTIES_SUCCESS:
    case FETCHING_UPDATE_USER_PROFILE_ATTRIBUTES_SUCCESS:
    case FETCHING_UPDATE_USER_INFO_SUCCESS:
    case FETCHING_UPDATE_USER_TENANT_SUCCESS:
    case FETCHING_UPDATE_USER_FAVORITES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        [action.user.info.id]: user(state[action.user.info.id], action),
        error: ''
      }
    case FETCHING_UPDATE_DEFAULT_SPACE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        [action.uid]: user(state[action.uid], action),
        error: ''
      }
    case REMOVE_EXPERIENCE_FROM_FAVORITES:
      return {
        ...state,
        [action.uid]: user(state[action.uid], action)
      }
    case UPDATE_USER_PERMISSIONS:
      return {
        ...state,
        [action.user.info.id]: user(state[action.user.info.id], action)
      }
    default:
      return state
  }
}

/**
 * Action creators for users
 */

function authUser (uid) {
  return {
    type: AUTH_USER,
    uid
  }
}

function unauthUser () {
  return {
    type: UNAUTH_USER
  }
}

function fetchingIsUserAuthenticated () {
  return {
    type: FETCHING_IS_USER_AUTHENTICATED
  }
}

function fetchingIsUserAuthenticatedFailure () {
  return {
    type: FETCHING_IS_USER_AUTHENTICATED_FAILURE,
    error: 'errors.user.isAuthenticated.fetching'
  }
}

function fetchingIsUserAuthenticatedSuccess (uid, user, timestamp) {
  return {
    type: FETCHING_IS_USER_AUTHENTICATED_SUCCESS,
    uid,
    user,
    timestamp
  }
}

function fetchingUser () {
  return {
    type: FETCHING_USER
  }
}

function fetchingUserFailure () {
  return {
    type: FETCHING_USER_FAILURE,
    error: 'errors.user.fetching'
  }
}

function fetchingUserSuccess (uid, user, timestamp) {
  return {
    type: FETCHING_USER_SUCCESS,
    uid,
    user,
    timestamp
  }
}

// function updatingUserInfo () {
//   return {
//     type: FETCHING_UPDATE_USER_INFO,
//     user
//   }
// }

// function updatingUserInfoFailure () {
//   return {
//     type: FETCHING_UPDATE_USER_INFO_FAILURE,
//     user
//   }
// }

function updatingUserInfoSuccess (user) {
  return {
    type: FETCHING_UPDATE_USER_INFO_SUCCESS,
    user
  }
}

function updatingUserProperties () {
  return {
    type: FETCHING_UPDATE_USER_INFO_PROPERTIES,
    user
  }
}

function updatingUserPropertiesFailure () {
  return {
    type: FETCHING_UPDATE_USER_INFO_PROPERTIES_FAILURE,
    user
  }
}

function updatingUserPropertiesSuccess (user, properties) {
  return {
    type: FETCHING_UPDATE_USER_INFO_PROPERTIES_SUCCESS,
    user,
    properties
  }
}

function updatingUserProfileAttributes () {
  return {
    type: FETCHING_UPDATE_USER_PROFILE_ATTRIBUTES,
    user
  }
}

function updatingUserProfileAttributesFailure () {
  return {
    type: FETCHING_UPDATE_USER_PROFILE_ATTRIBUTES_FAILURE,
    user
  }
}

function updatingUserProfileAttributesSuccess (user, attributes) {
  return {
    type: FETCHING_UPDATE_USER_PROFILE_ATTRIBUTES_SUCCESS,
    user,
    attributes
  }
}

function updatingFavorites () {
  return {
    type: FETCHING_UPDATE_USER_FAVORITES
  }
}
function updatingFavoritesFailure () {
  return {
    type: FETCHING_UPDATE_USER_FAVORITES_FAILURE
  }
}
function updatingFavoritesSuccess (user, favorites) {
  return {
    type: FETCHING_UPDATE_USER_FAVORITES_SUCCESS,
    user,
    favorites
  }
}

function removeExperienceFromFavorites (uid, experience) {
  return {
    type: REMOVE_EXPERIENCE_FROM_FAVORITES,
    uid,
    experience
  }
}

function fetchingUpdateDefaultSpace (uid, defaultSpace) {
  return {
    type: FETCHING_UPDATE_DEFAULT_SPACE,
    uid,
    defaultSpace
  }
}

function fetchingUpdateDefaultSpaceFailure (uid, defaultSpace) {
  return {
    type: FETCHING_UPDATE_DEFAULT_SPACE_SUCCESS,
    uid,
    defaultSpace
  }
}

function fetchingUpdateDefaultSpaceSuccess (uid, defaultSpace) {
  return {
    type: FETCHING_UPDATE_DEFAULT_SPACE_SUCCESS,
    uid,
    defaultSpace
  }
}

function updatingUserPermissions (user, permissions) {
  return {
    type: UPDATE_USER_PERMISSIONS,
    user,
    permissions
  }
}

export function updateUserPermissions (user, permissions, dispatch) {
  dispatch(updatingUserPermissions(user, permissions))
}

export function fetchAndHandleIsUserAuthed (dispatch) {
  // redux thunk pattern
  dispatch(fetchingIsUserAuthenticated())
  return api
    .isAuthed()
    .then(user => {
      dispatch(authUser(user.uid)) // first - redirects work better with this before fetchingIsUserAuthenticatedSuccess
      dispatch(fetchingIsUserAuthenticatedSuccess(user.uid, user, Date.now()))
      // const properties = [{ name: 'lastActive', value: Date.now() }]
      // if (!user.properties.firstActive) {
      //  properties.push({ name: 'firstActive', value: Date.now() })
      // }
      // profileApi.setUserAttributes(user.uid, properties)
    })
    .catch(error => {
      dispatch(fetchingIsUserAuthenticatedFailure('errors.user.not.logged.in', error))
    })
}

export function fetchAndHandleAuthedUser (username, password, dispatch) {
  const payload = {
    username: username,
    password: password
  }

  return api
    .auth(payload)
    .then(user => {
      dispatch(fetchingUser())
      dispatch(authUser(user.uid)) // first - redirects work better with this before fetchingUserSuccess
      dispatch(fetchingUserSuccess(user.uid, user, Date.now()))
      return user
    })
    .catch(error => dispatch(fetchingUserFailure(error)))
  /*
  return function (dispatch) {
    // redux thunk pattern
    // dispatch(fetchingUser())

    const { elements } = event.target
    const payload = {
      username: elements['username'].value,
      password: elements['password'].value
    }

    return api
      .auth(payload)
      .then(user => {
        dispatch(fetchingUser())
        dispatch(authUser(user.uid)) // first - redirects work better with this before fetchingUserSuccess
        dispatch(fetchingUserSuccess(user.uid, user, Date.now()))
      })
      .catch(error => dispatch(fetchingUserFailure(error)))
  }
  */
}

export function fetchAndHandleUser (uid) {
  return function (dispatch) {
    dispatch(fetchingUser())

    return api
      .getUserInfo(uid)
      .then(response => {
        const user = response.data
        dispatch(fetchingUserSuccess(user.id, user, Date.now()))
      })
      .catch(error => dispatch(fetchingUserFailure(error)))
  }
}

/**
 * Allows direct access to update the user object in the store by simply replacing the existing object
 * with a new one that has been updated prior to passing it in
 *
 * @param {*} user
 */
export function handleUpdateUserInfoSuccess (user, dispatch) {
  // return function (dispatch) {
  dispatch(updatingUserInfoSuccess(user))
  // }
}

export function handleUpdateUser (user, properties) {
  return function (dispatch) {
    dispatch(updatingUserProperties())

    const keyValuePairs = arrayKeyValueToObject(properties, 'name', 'value')
    dispatch(updatingUserPropertiesSuccess(user, keyValuePairs))
  }
}

export function handleUpdateUserProperties (user, properties, dispatch) {
  dispatch(updatingUserProperties())
  return api
    .setUserProperties(user.info.id, properties)
    .then(response => {
      const keyValuePairs = arrayKeyValueToObject(properties, 'name', 'value')
      dispatch(updatingUserPropertiesSuccess(user, keyValuePairs))
      return response
    })
    .catch(error => {
      dispatch(updatingUserPropertiesFailure())
      throw error
    })
}

export function handleUpdateUserProfileAttributes (user, attributes, dispatch, t) {
  dispatch(updatingUserProfileAttributes())
  return profileApi
    .setUserAttributes(user.info.id, attributes)
    .then(response => {
      const keyValuePairs = arrayKeyValueToObject(attributes, 'name', 'value')
      dispatch(updatingUserProfileAttributesSuccess(user, keyValuePairs))

      // change the language
      if (keyValuePairs.language) {
        const previousLang = i18n.language
        i18n.changeLanguage(keyValuePairs.language, (error, t) => {
          handleNotificationAdd(
            {
              type: 'success',
              headline: t('notifications.default.success.headline'),
              message: 'Successfully updated user profile'
            },
            dispatch
          )
          if (error) return i18n.changeLanguage(previousLang)
        })
      } else {
        handleNotificationAdd(
          {
            type: 'success',
            headline: t('notifications.default.success.headline'),
            message: 'Successfully updated user profile'
          },
          dispatch
        )
      }

      return response
    })
    .catch(() => {
      handleNotificationAdd(
        {
          type: 'danger',
          headline: t('notifications.default.fail.headline'),
          message: 'Unable to update user profile'
        },
        dispatch
      )
      dispatch(updatingUserProfileAttributesFailure())
    })
}

export function fetchAndHandleAddExperienceToFavorites (user, experienceId, dispatch, t) {
  dispatch(updatingFavorites())
  return favoritesApi
    .addExperience(experienceId)
    .then(result => {
      dispatch(updatingFavoritesSuccess(user, result.data))
      return result.data
    })
    .catch(() => {
      handleNotificationAdd(
        {
          type: 'danger',
          headline: t('notifications.default.fail.headline'),
          message: 'Unable to star this experience'
        },
        dispatch
      )
      dispatch(updatingFavoritesFailure())
    })
}

export function fetchAndHandleRemoveExperienceFromFavorites (user, experienceId, dispatch, t) {
  dispatch(updatingFavorites())
  return favoritesApi
    .removeExperience(experienceId)
    .then(result => {
      dispatch(updatingFavoritesSuccess(user, result.data))
      return result.data
    })
    .catch(() => {
      handleNotificationAdd(
        {
          type: 'danger',
          headline: t('notifications.default.fail.headline'),
          message: 'Unable to unstar this experience'
        },
        dispatch
      )
      dispatch(updatingFavoritesFailure())
    })
}

export function handleRemoveExperienceFromFavorites (uid, experience, dispatch) {
  dispatch(removeExperienceFromFavorites(uid, experience))
  return {}
}

export function handleUpdateDefaultSpace (uid, spaceId, handleNotificationAdd, t) {
  return function (dispatch) {
    dispatch(fetchingUpdateDefaultSpace(uid, spaceId))

    return profileApi
      .changeDefaultSpace(uid, spaceId)
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchingUpdateDefaultSpaceSuccess(uid, spaceId))
          handleNotificationAdd({
            type: 'success',
            headline: t('notifications.default.success.headline'),
            message: 'Successfully updated default space'
          })
        } else {
          dispatch(fetchingUpdateDefaultSpaceFailure(uid, spaceId))
          handleNotificationAdd({
            type: 'danger',
            headline: t('notifications.default.fail.headline'),
            message: 'Unable to update default space'
          })
        }
        return response
      })
      .catch(() => dispatch(dispatch(fetchingUpdateDefaultSpaceFailure(uid, spaceId))))
  }
}

export function logoutAndUnauth (dispatch) {
  dispatch(unauthUser())
}
