// Only continue if polyfills are actually needed
if (!('scrollBehavior' in document.documentElement.style)) {
  // Wait until the Polyfills are loaded
  Promise
    .all([
      import('smoothscroll-polyfill'),
      import('smoothscroll-anchor-polyfill')
    ])
    .then(([smoothscrollPolyfill/* , smoothscrollAnchorPolyfill */]) => {
      // (Unlike this package, smoothscroll-polyfill needs to be actively invoked: )
      smoothscrollPolyfill.polyfill()
    })
}
