import Constants from '../../common/utils/Constants'

/**
 * Servicing the top level `notifications` object in the redux store
 *
 * Notifications are based on https://chadly.github.io/react-bs-notifier/
 */
export function NotificationsException (message) {
  return {
    name: 'NotificationsException',
    message
  }
}

/**
 * Actions
 */
const NOTIFICATION_ADD = 'NOTIFICATION_ADD'
const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE'

/**
 * Initial states for reducers
 */
const initialNotificationsState = {
  items: [
    // {
    //   type: 'danger', //  supported values for type are one of info, warning, danger, or success. If no type is specified, it is assumed to be info.
    //   headline: 'Crikey!',
    //   message: 'Something bad happened'
    // },
    // {
    //   type: 'info',
    //   headline: 'Crikey!',
    //   message: 'Something infoish happened'
    // },
    // {
    //   type: 'success',
    //   headline: 'Crikey!',
    //   message: 'Something great happened'
    // }
  ] // all the notification objects available
}

/**
 * Reducers for notifications
 */

export default function notifications (state = initialNotificationsState, action) {
  switch (action.type) {
    case NOTIFICATION_ADD:
      return {
        ...state,
        items: [...state.items, action.item]
      }
    case NOTIFICATION_REMOVE:
      const updateItems = state.items.filter(item => item !== action.item)

      return {
        ...state,
        items: updateItems
      }
    default:
      return state
  }
}

/**
 * Action creators for blueprints
 */

export function notificationAdd (item) {
  return {
    type: NOTIFICATION_ADD,
    item
  }
}

export function notificationRemove (item) {
  return {
    type: NOTIFICATION_REMOVE,
    item
  }
}

export function handleNotificationAdd (item, dispatch) {
  const message = {
    // default message, in case `item` does not contain the correct members
    ...{
      type: 'error',
      headline: 'Headline missing',
      message: 'Message is missing.'
    },
    // overwrite the dafault message with whatever is in `item`
    ...item
  }

  // redux thunk pattern
  if (dispatch) {
    dispatch(notificationAdd(message))
    setTimeout(() => {
      dispatch(notificationRemove(message))
    }, Constants.alerts.DISMISS_TIMEOUT)
  } else {
    return function (dispatch) {
      dispatch(notificationAdd(message))
      setTimeout(() => {
        dispatch(notificationRemove(message))
      }, Constants.alerts.DISMISS_TIMEOUT)
    }
  }
}

export function handleNotificationRemove (item, dispatch) {
  dispatch(notificationRemove(item))
}
