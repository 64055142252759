/**
 * Servicing the top level `editing` object in the redux store
 */

// const exampleSchema = {
//   editing: {
//     isActive: true, // true | false

//     // All the rest are dependant on editing.isActive === true
//     mode: 'edit', // edit | preview
//     view: 'desktop', // desktop |  tablet | mobile - for viewing of the dessign
//     root: {
//       id: ':experienceKey',
//       type: 'experiences' // experiences | header | something else
//     },
//     focus: {
//       id: ':panelId',
//       type: 'panel', // panel | header |  section | row
//       action: 'edit' // not sure about this - could be used to determine what is shown in the left/edit panel,
//       ancestor: {
//         id: ':pageId',
//         type: 'page', // page | something else
//       },
//       initialState: {  // the content/config of the panel before it was edited
//         content: '<p>👋🌎</p>'
//       }
//     }
//     dragging: { // to share what is currently being dragged
//       id: ':panelId',
//       type: 'panel', // panel | row
//     }
//   }
// }
export const EditingException = message => {
  return {
    name: 'EditingException',
    message
  }
}
/**
 * Actions
 */
const EDITING_IS_ACTIVE = 'EDITING_IS_ACTIVE'
const EDITING_IS_NOT_ACTIVE = 'EDITING_IS_NOT_ACTIVE'
const EDITING_FOCUS = 'EDITING_FOCUS'
const EDITING_PAGE = 'EDITING_PAGE'
const EDITING_HEADER = 'EDITING_HEADER'
/**
 * Initial states for reducers
 */
const initialStateForEditing = {
  isActive: false
}
/**
 * Reducer for `editing`
 */
export default function editing (state = initialStateForEditing, action) {
  switch (action.type) {
    case EDITING_IS_ACTIVE:
      return {
        // set the root & focus objects here:
        ...action.state,
        // everything else reset to the default:
        isActive: true,
        mode: 'edit',
        view: 'desktop'
      }
    case EDITING_IS_NOT_ACTIVE:
      return {
        isActive: false // reset everything
      }
    case EDITING_FOCUS:
      return {
        ...state,
        focus: action.focus
      }

    case EDITING_HEADER:
      return {
        ...state,
        header: action.header
      }
    case EDITING_PAGE:
      return {
        ...state,
        page: action.page
      }
    default:
      return state
  }
}
/**
 * Action creators for `editing`
 */
export const setEditingIsActive = state => {
  return {
    type: EDITING_IS_ACTIVE,
    state
  }
}
export const setEditingIsNotActive = () => {
  return {
    type: EDITING_IS_NOT_ACTIVE
  }
}
export const setEditingFocus = focus => {
  return {
    type: EDITING_FOCUS,
    focus
  }
}
export const updateEditingPage = page => {
  return {
    type: EDITING_PAGE,
    page
  }
}
export const updateEditingHeader = header => {
  return {
    type: EDITING_HEADER,
    header
  }
}
