import axios from 'axios'
import { getApiRoot } from '../utils/SystemUtils'

export default {

  getSpaceById (spaceId) {
    return axios
      .get(`${getApiRoot()}/api/spaces/${spaceId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getSpaceIdByKey (key) {
    return axios
      .get(`${getApiRoot()}/api/spaces/key/${key}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  uploadSpaceIcon (spaceId, logoBase64) {
    const payload = {
      image: logoBase64
    }

    return axios
      .post(`${getApiRoot()}/api/spaces/${spaceId}/icon`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  }
}
