/**
 * Servicing the  `experiences.[:experienceKey]` object in the redux store
 */

import { ERROR_EXPERIENCE_FETCHING, ERROR_EXPERIENCE_UNAUTHORIZED } from '../../common/utils/Constants'

/**
 * Actions
 */
export const FETCHING_EXPERIENCE = 'FETCHING_EXPERIENCE'
export const FETCHING_EXPERIENCE_FAILURE = 'FETCHING_EXPERIENCE_FAILURE'
export const FETCHING_EXPERIENCE_SUCCESS = 'FETCHING_EXPERIENCE_SUCCESS'
export const FETCHING_EXPERIENCE_UNAUTHORIZED = 'FETCHING_EXPERIENCE_UNAUTHORIZED'

export const FETCHING_UPDATE_PAGE_ORDER = 'FETCHING_UPDATE_PAGE_ORDER'
export const FETCHING_UPDATE_PAGE_ORDER_FAILURE = 'FETCHING_UPDATE_PAGE_ORDER_FAILURE'
export const FETCHING_UPDATE_PAGE_ORDER_SUCCESS = 'FETCHING_UPDATE_PAGE_ORDER_SUCCESS'

export const FETCHING_RENAME_PAGE = 'FETCHING_RENAME_PAGE'
export const FETCHING_RENAME_PAGE_FAILURE = 'FETCHING_RENAME_PAGE_FAILURE'
export const FETCHING_RENAME_PAGE_SUCCESS = 'FETCHING_RENAME_PAGE_SUCCESS'

export const UPDATE_PAGE_NAME = 'UPDATE_PAGE_NAME'
export const UPDATE_PAGE_DESCRIPTION = 'UPDATE_PAGE_DESCRIPTION'
export const UPDATE_PAGE_STATUS = 'UPDATE_PAGE_STATUS'

export const CHANGING_EXPERIENCE_SETTINGS = 'CHANGING_EXPERIENCE_SETTINGS'
export const CHANGING_EXPERIENCE_SETTINGS_FAILURE = 'CHANGING_EXPERIENCE_SETTINGS_FAILURE'
export const CHANGING_EXPERIENCE_SETTINGS_SUCCESS = 'CHANGING_EXPERIENCE_SETTINGS_SUCCESS'

export const UPDATE_EXPERIENCE_HEADER = 'UPDATE_EXPERIENCE_HEADER'

export const UPDATE_EXPERIENCE_MENU = 'UPDATE_EXPERIENCE_MENU'

export const UPDATE_EXPERIENCE_SOCIAL_SETTINGS = 'UPDATE_EXPERIENCE_SOCIAL_SETTINGS'

export const CHANGING_EXPERIENCE_BRANDING = 'CHANGING_EXPERIENCE_BRANDING'
export const CHANGING_EXPERIENCE_BRANDING_FAILURE = 'CHANGING_EXPERIENCE_BRANDING_FAILURE'
export const CHANGING_EXPERIENCE_BRANDING_SUCCESS = 'CHANGING_EXPERIENCE_BRANDING_SUCCESS'

export const UPDATE_PANEL_NAME = 'UPDATE_PANEL_NAME'
export const UPDATE_PANEL_CONTENT = 'UPDATE_PANEL_CONTENT'
export const UPDATE_PANEL_SETTINGS = 'UPDATE_PANEL_SETTINGS'

export const UPDATE_PANEL_CONFIG = 'UPDATE_PANEL_CONFIG'

export const GETTING_PAGE = 'GETTING_PAGE'
export const CREATING_PAGE = 'CREATING_PAGE'
export const CLONING_PAGE = 'CLONING_PAGE'
export const UPDATING_PAGE = 'UPDATING_PAGE'
export const DELETING_PAGE = 'DELETING_PAGE'

export const UPDATE_ROW_SETTINGS = 'UPDATE_ROW_SETTINGS'

export const FETCHING_UPDATE_PANEL_CONFIG = 'FETCHING_UPDATE_PANEL_CONFIG'
export const FETCHING_UPDATE_PANEL_CONFIG_FAILURE = 'FETCHING_UPDATE_PANEL_CONFIG_FAILURE'
export const FETCHING_UPDATE_PANEL_CONFIG_SUCCESS = 'FETCHING_UPDATE_PANEL_CONFIG_SUCCESS'

export const UPDATE_EXPERIENCE_PAGES = 'UPDATE_EXPERIENCE_PAGES'

/**
 * Initial states for reducers
 */
export const initialStateForIndividualExperience = {
  isFetching: false,
  error: null
}

/**
 * Reducer for experiences.[experience]
 */
export default function experience (state = initialStateForIndividualExperience, action) {
  switch (action.type) {
    case FETCHING_EXPERIENCE:
      return {
        ...state,
        isFetching: true
      }

    case FETCHING_EXPERIENCE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        info: action.experience,
        error: null
      }

    case FETCHING_EXPERIENCE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: ERROR_EXPERIENCE_FETCHING
      }

    case FETCHING_EXPERIENCE_UNAUTHORIZED:
      return {
        ...state,
        isFetching: false,
        error: ERROR_EXPERIENCE_UNAUTHORIZED
      }

    case FETCHING_UPDATE_PAGE_ORDER:
      return {
        ...state
      }

    case FETCHING_UPDATE_PAGE_ORDER_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          order: action.order
        },
        error: ''
      }

    case FETCHING_UPDATE_PAGE_ORDER_FAILURE:
      return {
        ...state,

        error: action.error
      }

    case FETCHING_RENAME_PAGE:
      return {
        ...state
      }

    case FETCHING_RENAME_PAGE_FAILURE:
      return {
        ...state,
        error: action.error
      }

    case FETCHING_RENAME_PAGE_SUCCESS:
      const pagesRename = Array.from(state.info.pages)
      const pageIndexRename = pagesRename.findIndex(page => page.id === action.pageId)
      pagesRename[pageIndexRename].name = action.name

      return {
        ...state,
        info: {
          ...state.info,
          pages: pagesRename
        }
      }

    case CHANGING_EXPERIENCE_SETTINGS_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          name: action.experience.name,
          description: action.experience.description,
          landingZone: action.experience.landingZone,
          accessLevel: action.experience.accessLevel
        }
      }

    case UPDATE_EXPERIENCE_HEADER:
      return {
        ...state,
        info: {
          ...state.info,
          name: action.name,
          branding: action.branding
        }
      }

    case UPDATE_EXPERIENCE_MENU:
      return {
        ...state,
        info: {
          ...state.info,
          navigation: action.menu
        }
      }

    case UPDATE_EXPERIENCE_SOCIAL_SETTINGS:
      return {
        ...state,
        info: {
          ...state.info,
          socialSettings: action.socialSettings
        }
      }

    case CHANGING_EXPERIENCE_BRANDING:
      return {
        ...state
      }

    case CHANGING_EXPERIENCE_BRANDING_FAILURE:
      return {
        ...state,
        error: action.error
      }

    case CHANGING_EXPERIENCE_BRANDING_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          branding: action.branding
        }
      }

    case UPDATE_PANEL_SETTINGS:
      const pagesX = Array.from(state.info.pages)
      const pageIndexX = pagesX.findIndex(page => page.id === action.pageId)
      const panelIndexX = pagesX[pageIndexX].panels.findIndex(panel => panel.id === action.panelId)
      const oldSettings = pagesX[pageIndexX].panels[panelIndexX].settings
      const newSettings = {
        ...oldSettings,
        ...action.settings
      }
      pagesX[pageIndexX].panels[panelIndexX].settings = newSettings

      return {
        ...state,
        info: {
          ...state.info,
          pages: pagesX
        }
      }

    case UPDATE_PANEL_NAME:
      const pagesY = Array.from(state.info.pages)
      const pageIndexY = pagesY.findIndex(page => page.id === action.pageId)
      const panelIndexY = pagesY[pageIndexY].panels.findIndex(panel => panel.id === action.panelId)

      pagesY[pageIndexY].panels[panelIndexY].name = action.name

      return {
        ...state,
        info: {
          ...state.info,
          pages: pagesY
        }
      }

    case UPDATE_PANEL_CONTENT:
      const pages = Array.from(state.info.pages)
      const pageIndex = pages.findIndex(page => page.id === action.pageId)
      const panelIndex = pages[pageIndex].panels.findIndex(panel => panel.id === action.panelId)

      pages[pageIndex].panels[panelIndex].content = action.content

      // const panel = page.panels.find(panel => panel.id === action.panelId)
      // console.info('panel to update:', panel)

      return {
        ...state,
        info: {
          ...state.info,
          pages
        }
      }

    case UPDATE_PANEL_CONFIG:
    case FETCHING_UPDATE_PANEL_CONFIG_SUCCESS:
      // Note: cannot use the same naming as any others in this case statement
      const pagesA = Array.from(state.info.pages)
      const pageIndexA = pagesA.findIndex(page => page.id === action.pageId)
      const panelIndexA = pagesA[pageIndexA].panels.findIndex(panel => panel.id === action.panelId)
      const oldConfig = pagesA[pageIndexA].panels[panelIndexA].config
      const newConfig = {
        ...oldConfig,
        ...action.config
      }
      pagesA[pageIndexA].panels[panelIndexA].config = newConfig
      return {
        ...state,
        info: {
          ...state.info,
          pages: pagesA
        }
      }

    case UPDATING_PAGE:
      const pagesArray = Array.from(state.info.pages)
      const targetPageIndex = pagesArray.findIndex(page => page.id === action.page.id)
      pagesArray[targetPageIndex] = action.page
      return {
        ...state,
        info: {
          ...state.info,
          pages: pagesArray
        }
      }

    case UPDATE_PAGE_NAME:
      return processUpdatePageName(state, action)

    case UPDATE_PAGE_DESCRIPTION:
      return processUpdatePageDescription(state, action)

    case UPDATE_PAGE_STATUS:
      return processUpdatePageStatus(state, action)

    case GETTING_PAGE:
      return processGetPage(state, action)
    case CREATING_PAGE:
    case CLONING_PAGE:
      return processCreatePage(state, action)

    case DELETING_PAGE:
      return processDeletePage(state, action)

    case UPDATE_ROW_SETTINGS:
      const pagesR = Array.from(state.info.pages)
      const pageIndexR = pagesR.findIndex(page => page.id === action.pageId)
      const rowIndex = pagesR[pageIndexR].rows.findIndex(row => row.id === action.rowId)
      const oldRowSettings = pagesR[pageIndexR].rows[rowIndex].settings
      const newRowSettings = {
        ...oldRowSettings,
        ...action.settings
      }
      pagesR[pageIndexR].rows[rowIndex].settings = newRowSettings

      return {
        ...state,
        info: {
          ...state.info,
          pagesR
        }
      }

    case UPDATE_EXPERIENCE_PAGES:
      return processUpdateExperiencePages(state, action)

    default:
      return state
  }
}

function processUpdateExperiencePages (state, action) {
  return {
    ...state,
    info: {
      ...state.info,
      pages: action.pages
    }
  }
}

function processGetPage (state, action) {
  const newPagesArray = Array.from(state.info.pages)
  // see if the page already exist in redux
  const targetPageIndex = newPagesArray.findIndex(page => page.id === action.pageId)
  if (targetPageIndex < 0) {
    // if not exist, we add to the list
    newPagesArray.push(action.page)
  } else {
    // if exists, we replace it with new data
    newPagesArray[targetPageIndex] = (action.page)
  }

  return {
    ...state,
    info: {
      ...state.info,
      // order: newPagesOrderArray,
      pages: newPagesArray
    }
  }
}

function processCreatePage (state, action) {
  const newPagesOrderArray = Array.from(state.info.order)
  newPagesOrderArray.push(action.page.id)
  const newPagesArray = Array.from(state.info.pages)
  newPagesArray.push(action.page)
  return {
    ...state,
    info: {
      ...state.info,
      order: newPagesOrderArray,
      pages: newPagesArray
    }
  }
}

function processDeletePage (state, action) {
  const newPagesOrderArray = Array.from(state.info.order)
  const targetOrderIndex = newPagesOrderArray.findIndex(id => id === action.pageId)
  newPagesOrderArray.splice(targetOrderIndex, 1)
  const newPagesArray = Array.from(state.info.pages)
  const targetPageIndex = newPagesArray.findIndex(page => page.id === action.pageId)
  newPagesArray.splice(targetPageIndex, 1)
  return {
    ...state,
    info: {
      ...state.info,
      order: newPagesOrderArray,
      pages: newPagesArray
    }
  }
}

function processUpdatePageName (state, action) {
  const pagesArray = Array.from(state.info.pages)
  const targetPageIndex = pagesArray.findIndex(page => page.id === action.pageId)
  pagesArray[targetPageIndex] = {
    ...pagesArray[targetPageIndex],
    name: action.name
  }
  return {
    ...state,
    info: {
      ...state.info,
      pages: pagesArray
    }
  }
}

function processUpdatePageDescription (state, action) {
  const pagesArray = Array.from(state.info.pages)
  const targetPageIndex = pagesArray.findIndex(page => page.id === action.pageId)
  pagesArray[targetPageIndex] = {
    ...pagesArray[targetPageIndex],
    description: action.description
  }
  return {
    ...state,
    info: {
      ...state.info,
      pages: pagesArray
    }
  }
}

function processUpdatePageStatus (state, action) {
  const pagesArray = Array.from(state.info.pages)
  const targetPageIndex = pagesArray.findIndex(page => page.id === action.pageId)
  pagesArray[targetPageIndex] = {
    ...pagesArray[targetPageIndex],
    status: action.status
  }
  const newState = {
    ...state,
    info: {
      ...state.info,
      pages: pagesArray
    }
  }
  if (action.navigation) {
    newState.info.navigation = action.navigation
  }
  return newState
}

export function fetchingUpdatePageOrder (experienceKey) {
  return {
    type: FETCHING_UPDATE_PAGE_ORDER,
    key: experienceKey
  }
}

export function fetchingUpdatePageOrderFailure (experienceKey) {
  return {
    type: FETCHING_UPDATE_PAGE_ORDER_FAILURE,
    key: experienceKey,
    error: 'error.space.page.order.update'
  }
}

export function fetchingUpdatePageOrderSuccess (experienceKey, order) {
  return {
    type: FETCHING_UPDATE_PAGE_ORDER_SUCCESS,
    key: experienceKey,
    order,
    error: ''
  }
}

export function fetchingRenamePage (experienceKey) {
  return {
    type: FETCHING_RENAME_PAGE,
    key: experienceKey
  }
}

export function fetchingRenamePageFailure (experienceKey, pageId, error) {
  return {
    type: FETCHING_RENAME_PAGE_FAILURE,
    key: experienceKey,
    pageId,
    error
  }
}
export function fetchingRenamePageSuccess (experienceKey, pageId, name) {
  return {
    type: FETCHING_RENAME_PAGE_SUCCESS,
    key: experienceKey,
    pageId,
    name,
    error: ''
  }
}
