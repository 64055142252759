import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const AuthHeader = () => {
  const tenant = useSelector(state => state.tenant)
  const logo = tenant && tenant.info && tenant.info.branding && tenant.info.branding.logo ? tenant.info.branding.logo : '/images/aloha-tm.svg'

  return (
    <header className="auth-header text-center">
      <h1 className="auth-title">
        <div className="logo full">
          <Link to="/">
            <img src={logo} alt={tenant.info.name} />
          </Link>
        </div>
      </h1>
    </header>
  )
}
AuthHeader.propTypes = {
  logo: PropTypes.string
}

export default AuthHeader
