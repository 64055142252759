import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as qs from 'query-string'
import isEmpty from 'lodash/isEmpty'

import { LoadingIndicator } from '../../components'

class RootContainer extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      isLoading: true
    }
  }

  /**
   * Check if there is an authed user and redirect accordingly
   */
  componentDidMount () {
    // this is a workaround for loading the /welcome page without needing the react app to be loaded first. if the react
    // app is not already loaded, which will be the case for 1st time user, the react router will not be ready to handle
    // the /welcome route
    const { location, users, tenant } = this.props
    const token = qs.parse(location.search).token
    let redirectTo
    if (token) {
      redirectTo = `/welcome?token=${token}`
    } else {
      if (users && users.isFetching === false && users.isAuthed === true) {
        // user is logged in
        if (isEmpty(tenant.info.homepage)) {
          redirectTo = '/experiences'
        } else {
          redirectTo = `/experiences/${tenant.info.homepage}`
        }
      } else {
        // user is anonymous, so let's check if the tenant has allowAnonymous enabled
        if (tenant && tenant.info && tenant.info.security.allowAnonymous) {
          // tenant allows anonymous access, so now let's see if there is a default landing page, or go to the experiences list page
          if (isEmpty(tenant.info.homepage)) {
            redirectTo = '/experiences'
          } else {
            redirectTo = `/experiences/${tenant.info.homepage}`
          }
        } else {
          // tenant does not allow anonymous access, redirect to auth page
          redirectTo = '/auth'
        }
      }
    }

    this.setState({
      redirectTo,
      isLoading: false
    })
  }

  render () {
    const { redirectTo, isLoading } = this.state
    return isLoading ? <LoadingIndicator /> : <Redirect to={redirectTo} />
  }
}

function mapStateToProps ({ users, tenant }) {
  return {
    users: users,
    tenant: tenant
  }
}

export default connect(mapStateToProps)(RootContainer)
