import React from 'react'
import ContentLoader from 'react-content-loader'

export default function PaginationLoader () {
  return (
    <ContentLoader height={28} width={120} speed={2} style={{ width: '120px' }}>
      <rect x="0" y="0" rx="3" ry="3" width="26" height="28" />
      <rect x="30" y="0" rx="3" ry="3" width="26" height="28" />
      <rect x="60" y="0" rx="3" ry="3" width="26" height="28" />
      <rect x="90" y="0" rx="3" ry="3" width="26" height="28" />
    </ContentLoader>
  )
}
