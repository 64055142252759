import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { ErrorMessage, Field, Formik } from 'formik'
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar'
import * as Yup from 'yup'

import './LeftNav.css'
import { useSelector } from 'react-redux'
import { EXPERIENCES } from '../../redux/modules/experiences'

const DEFAULT_MENU_ITEMS = [
  {
    text: 'Outlook',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Outlook.com_icon_%282012-2019%29.svg/2028px-Outlook.com_icon_%282012-2019%29.svg.png',
    url: 'https://outlook.office.com'
  },
  {
    text: 'Mattermost',
    icon: 'https://www.svgrepo.com/show/354049/mattermost-icon.svg',
    url: 'https://mattermost.com'
  },
  {
    text: 'LCI Customer Service',
    icon: 'https://cdn.iconscout.com/icon/premium/png-256-thumb/customer-support-18-496809.png',
    url: '#'
  },
  {
    text: 'LCI Helpdesk',
    icon: 'https://cdn.iconscout.com/icon/premium/png-256-thumb/help-desk-6591087-5469092.png',
    url: '#'
  },
  {
    text: 'UKG',
    icon: '/images/ukg_logo.png',
    url: '#'
  },
  {
    text: 'AX',
    icon: 'https://api.nuget.org/v3-flatcontainer/crm.sdk.core/10.3.0/icon',
    url: '#'
  },
  {
    text: 'OnlyOffice',
    icon: 'https://lh3.googleusercontent.com/-x-iOybK-n2Y/XW5fRXeIEOI/AAAAAAAAAKQ/JTC1vv5ihFoCU7whHkb_EZrvOeUZN4tEQCLcBGAs/s400/icon_128.png',
    url: 'https://office.cyemptive.com'
  }
]

const LeftNav = (props) => {
  const { t } = useTranslation()
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [menuItems, setMenuItems] = useState([])
  const [isConfigure, setIsConfigure] = useState(false)
  const [modal, setModal] = useState({ isOpen: false, experience: {}, index: -1 })
  const isEditing = useSelector(state => state.editing.isActive && state.editing.root.type === EXPERIENCES)
  const validationSchema = Yup.object().shape({
    text: Yup.string().required(t('common.validation.required')),
    url: Yup.string().required(t('common.validation.required')),
    icon: Yup.string().required(t('common.validation.required'))
  })
  useEffect(() => {
    setMenuItems(DEFAULT_MENU_ITEMS)
  }, [])

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }
  const toggleConfigure = () => {
    setIsCollapsed(false)
    setIsConfigure(!isConfigure)
  }
  const deleteMenuItem = () => {}
  const closeModal = () => {
    setModal({
      ...modal,
      isOpen: false
    })
  }
  const addMenuItem = (values, { setSubmitting }) => {
    const newMenuItems = Array.from(menuItems)
    newMenuItems.push(values)
    setMenuItems(newMenuItems)
    setSubmitting(false)
    closeModal()
  }

  return !isEditing && (
    <>
      <Sidebar collapsed={isCollapsed} toggled={isCollapsed} backgroundColor="#003DA5" width="300px" transitionDuration={500}>
        <br/>
        <Menu>
          {menuItems.map((item, index) => {
            return (
              <MenuItem key={index} className="left-menu-item"
                icon={<img src={item.icon} width="32" height="32" alt={item.text} />}
                component={<a href={item.url} target="_blank" rel="noopener noreferrer">{item.text}</a>}
              >
                {item.text}
              </MenuItem>
            )
          })}
        </Menu>
        {/* isConfigure && (
          <div style={{ filter: 'brightness(0) invert(1)' }}>
            <span className="fa fa-plus" />
            <Link to="#" onClick={e => toggleModal(e)}>
              Add Link
            </Link>
          </div>
        ) */}
        <div className="w-100" style={{ padding: '0 20px' }}>
          <br/><br/><br/><br/>
          <button className="btn btn-sm btn-outline-light w-100" onClick={toggleCollapse}>
            {isCollapsed ? (
              <span className="fa fa-arrow-right" />
            ) : (
              <span className="fa fa-arrow-left" />
            )}
          </button>
        </div>
        {/*
        <div className="w-100" style={{ position: 'absolute' }}>
          {isConfigure ? (
            <button className="btn btn-sm btn-outline-light w-100" onClick={toggleConfigure}>
              Done
            </button>
          ) : (
            <button className="btn btn-sm btn-outline-light w-100" onClick={toggleConfigure}>
              Configure
            </button>
          )}
        </div>
        */}
      </Sidebar>
      <Modal show={modal.isOpen} onHide={closeModal} backdrop="static">
        <Modal.Header closeButton>
          <h5 className="m-0">Add menu item</h5>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              validateOnBlur={false}
              onSubmit={addMenuItem}
              initialValues={{ text: '', ur: '', icon: '' }}
              validationSchema={validationSchema}
              render={({ touched, errors, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit.bind(this)}>
                  <div className="form-group">
                    <label className="control-label" htmlFor="name">
                      Link Text
                      <span className="icon-required" />
                    </label>
                    <Field type="text" className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`} name="text" autoFocus="autoFocus" />
                    <ErrorMessage name="text" component="div" className="text-danger" />
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="name">
                      Link URL
                      <span className="icon-required" />
                    </label>
                    <Field type="text" className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`} name="url" />
                    <ErrorMessage name="url" component="div" className="text-danger" />
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="name">
                      Link Icon
                      <span className="icon-required" />
                    </label>
                    <Field type="text" className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`} name="icon" />
                    <ErrorMessage name="icon" component="div" className="text-danger" />
                  </div>
                  <div className="form-group pull-right mb-0">
                    <button type="button" className="btn btn-sm btn-outline-secondary" onClick={closeModal} disabled={isSubmitting}>
                      Cancel
                    </button>
                    &nbsp;
                    <button type="submit" className="btn btn-sm btn-primary" disabled={isSubmitting}>
                      Add
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default LeftNav
