import React from 'react'

import AuthHeader from '../../legacy/components/Cards/Auth/AuthHeader'

import './NotAuthenticatedLayout.css'

const NotAuthenticatedLayout = ({ children }) => {
  return (
    <div className="NotAuthenticatedLayout auth">
      <div className="auth-container">
        <div className="auth-card-wrap">
          <AuthHeader />
          <div className="auth-content pt-5 pb-0">{children}</div>
        </div>
      </div>
    </div>
  )
}
export default NotAuthenticatedLayout
