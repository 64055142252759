/**
 * Servicing the top level `tenant` object in the redux store
 */
import move from 'lodash-move'

import spaceApi from '../../common/apis/Api'
import api from '../../common/apis/v2/Api'
import experienceApi from '../../common/apis/ExperienceApi'
import contentApi from '../../common/apis/ContentApi'
import panelApi from '../../common/apis/PanelApi'
import pageApi, { getPage } from '../../common/apis/PageApi'

import experience, {
  fetchingUpdatePageOrder,
  fetchingUpdatePageOrderSuccess,
  fetchingUpdatePageOrderFailure,
  fetchingRenamePageSuccess,
  FETCHING_UPDATE_PAGE_ORDER,
  FETCHING_UPDATE_PAGE_ORDER_FAILURE,
  FETCHING_UPDATE_PAGE_ORDER_SUCCESS,
  FETCHING_RENAME_PAGE,
  FETCHING_RENAME_PAGE_FAILURE,
  FETCHING_RENAME_PAGE_SUCCESS,
  FETCHING_EXPERIENCE,
  FETCHING_EXPERIENCE_FAILURE,
  FETCHING_EXPERIENCE_UNAUTHORIZED,
  FETCHING_EXPERIENCE_SUCCESS,
  CHANGING_EXPERIENCE_SETTINGS,
  CHANGING_EXPERIENCE_SETTINGS_FAILURE,
  CHANGING_EXPERIENCE_SETTINGS_SUCCESS,
  CHANGING_EXPERIENCE_BRANDING,
  CHANGING_EXPERIENCE_BRANDING_FAILURE,
  CHANGING_EXPERIENCE_BRANDING_SUCCESS,
  UPDATE_EXPERIENCE_HEADER,
  UPDATE_EXPERIENCE_MENU,
  UPDATE_PANEL_NAME,
  UPDATE_PANEL_CONTENT,
  UPDATE_PANEL_SETTINGS,
  UPDATE_PANEL_CONFIG,
  FETCHING_UPDATE_PANEL_CONFIG,
  FETCHING_UPDATE_PANEL_CONFIG_FAILURE,
  FETCHING_UPDATE_PANEL_CONFIG_SUCCESS,
  GETTING_PAGE,
  CREATING_PAGE,
  UPDATING_PAGE,
  DELETING_PAGE,
  UPDATE_ROW_SETTINGS,
  UPDATE_PAGE_NAME,
  UPDATE_PAGE_STATUS,
  CLONING_PAGE,
  UPDATE_PAGE_DESCRIPTION,
  UPDATE_EXPERIENCE_SOCIAL_SETTINGS,
  UPDATE_EXPERIENCE_PAGES
} from './experience'
import { STATUS_TRASHED } from '../../common/utils/Constants'

export function ExperiencesException (message) {
  return {
    name: 'ExperiencesException',
    message
  }
}

export const EXPERIENCES = 'experiences'

const CLEAR_EXPERIENCES = 'CLEAR_EXPERIENCES'
const DELETE_EXPERIENCE = 'DELETE_EXPERIENCE'

/**
 * Initial states for reducers
 */
const initialStateForAllExperiences = {
  isFetching: false,
  isEditing: false
}

/**
 * Reducer for experiences
 */
export default function experiences (state = initialStateForAllExperiences, action) {
  switch (action.type) {
    // Individual experiences - pass everything thru
    case FETCHING_EXPERIENCE:
    case FETCHING_EXPERIENCE_SUCCESS:
    case FETCHING_EXPERIENCE_FAILURE:
    case FETCHING_EXPERIENCE_UNAUTHORIZED:
    case FETCHING_UPDATE_PAGE_ORDER:
    case FETCHING_UPDATE_PAGE_ORDER_SUCCESS:
    case FETCHING_UPDATE_PAGE_ORDER_FAILURE:
    case FETCHING_RENAME_PAGE:
    case FETCHING_RENAME_PAGE_SUCCESS:
    case FETCHING_RENAME_PAGE_FAILURE:
    case CHANGING_EXPERIENCE_SETTINGS:
    case CHANGING_EXPERIENCE_SETTINGS_SUCCESS:
    case CHANGING_EXPERIENCE_SETTINGS_FAILURE:
    case CHANGING_EXPERIENCE_BRANDING:
    case CHANGING_EXPERIENCE_BRANDING_SUCCESS:
    case CHANGING_EXPERIENCE_BRANDING_FAILURE:
    case UPDATE_EXPERIENCE_HEADER:
    case UPDATE_EXPERIENCE_MENU:
    case UPDATE_PANEL_NAME:
    case UPDATE_PANEL_SETTINGS:
    case UPDATE_PANEL_CONFIG:
    case UPDATE_PANEL_CONTENT:
    case FETCHING_UPDATE_PANEL_CONFIG:
    case FETCHING_UPDATE_PANEL_CONFIG_SUCCESS:
    case FETCHING_UPDATE_PANEL_CONFIG_FAILURE:
    case UPDATING_PAGE:
    case DELETING_PAGE:
    case GETTING_PAGE:
    case CREATING_PAGE:
    case CLONING_PAGE:
    case UPDATE_PAGE_NAME:
    case UPDATE_PAGE_DESCRIPTION:
    case UPDATE_PAGE_STATUS:
    case UPDATE_ROW_SETTINGS:
    case UPDATE_EXPERIENCE_SOCIAL_SETTINGS:
    case UPDATE_EXPERIENCE_PAGES:
      return {
        ...state,
        [action.key]: experience(state[action.key], action)
      }
    case DELETE_EXPERIENCE:
      return getDeleteExperienceState(action.key, state)
    case CLEAR_EXPERIENCES:
      return initialStateForAllExperiences
    default:
      return state
  }
}

function getDeleteExperienceState (key, currentState) {
  const newState = {
    ...currentState
  }
  delete newState[key]
  return newState
}

/**
 * Action creators for an individual experience
 */
export function handleDeleteExperience (key, dispatch) {
  dispatch(deleteExperience(key))
}

function deleteExperience (key) {
  return {
    type: DELETE_EXPERIENCE,
    key
  }
}

export function fetchingExperience (key) {
  return {
    type: FETCHING_EXPERIENCE,
    key
  }
}

export function fetchingExperienceFailure (key, error) {
  return {
    type: FETCHING_EXPERIENCE_FAILURE,
    key,
    error
  }
}

export function fetchingExperienceUnauthorized (key, error) {
  return {
    type: FETCHING_EXPERIENCE_UNAUTHORIZED,
    key,
    error
  }
}

export function fetchingExperienceSuccess (key, experience) {
  return {
    type: FETCHING_EXPERIENCE_SUCCESS,
    key,
    experience
  }
}

function changingExperienceSettings (key) {
  return {
    type: CHANGING_EXPERIENCE_SETTINGS,
    key
  }
}

function changingExperienceSettingsFailure (key, error) {
  return {
    type: CHANGING_EXPERIENCE_SETTINGS_FAILURE,
    key,
    error
  }
}

export function updateExperienceHeader (key, name, branding) {
  return {
    type: UPDATE_EXPERIENCE_HEADER,
    key,
    name,
    branding
  }
}

function changingExperienceSettingsSuccess (key, experience) {
  return {
    type: CHANGING_EXPERIENCE_SETTINGS_SUCCESS,
    key,
    experience
  }
}

export function changingExperienceBranding (key) {
  return {
    type: CHANGING_EXPERIENCE_BRANDING,
    key
  }
}

export function changingExperienceBrandingFailure (key, error) {
  return {
    type: CHANGING_EXPERIENCE_BRANDING_FAILURE,
    key,
    error
  }
}

export function changingExperienceBrandingSuccess (key, branding) {
  return {
    type: CHANGING_EXPERIENCE_BRANDING_SUCCESS,
    key,
    branding
  }
}

/**
 * Update page name
 */
export function handleUpdatePageName (key, pageId, name, dispatch) {
  dispatch(updatePageName(key, pageId, name))
}

function updatePageName (key, pageId, name) {
  return {
    type: UPDATE_PAGE_NAME,
    key,
    pageId,
    name
  }
}

/**
 * Update page description
 */
export function handleUpdatePageDesc (key, pageId, description, dispatch) {
  dispatch(updatePageDesc(key, pageId, description))
}

function updatePageDesc (key, pageId, description) {
  return {
    type: UPDATE_PAGE_DESCRIPTION,
    key,
    pageId,
    description
  }
}

export function updatePanelName (key, pageId, panelId, name) {
  return {
    type: UPDATE_PANEL_NAME,
    key,
    pageId,
    panelId,
    name
  }
}

export function updatePanelContent (key, pageId, panelId, content) {
  return {
    type: UPDATE_PANEL_CONTENT,
    key,
    pageId,
    panelId,
    content
  }
}

export function updatePanelSettings (key, pageId, panelId, settings) {
  return {
    type: UPDATE_PANEL_SETTINGS,
    key,
    pageId,
    panelId,
    settings
  }
}

export function updatePanelConfig (key, pageId, panelId, config) {
  return {
    type: UPDATE_PANEL_CONFIG,
    key,
    pageId,
    panelId,
    config
  }
}

export function fetchAndHandleExperienceByKey (key, dispatch) {
  // return function (dispatch) { // don't need to return a function - looks like useDispatch already does the think stuff
  dispatch(fetchingExperience(key))
  return api
    .getExperienceByKey(key)
    .then(response => {
      if (response.status === 200) {
        dispatch(fetchingExperienceSuccess(key, response.data))
      } else {
        dispatch(fetchingExperienceFailure(key, response))
      }
      return response
    })
    .then(response => {
      if (response.data && response.data.branding) {
        // we have branding, so just return the response
        return response
      } else {
        // set branding if it's null
        response.data.branding = {
          banner: 'default.jpg',
          headerAlignment: 'center',
          headerHeight: 'md',
          headerBackgroundColor: '#85ce36',
          showBackgroundImage: true,
          headerBackgroundPlacement: 'middle',
          custom: 'Client side fix for NULL branding'
        }
        return response
      }
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        dispatch(fetchingExperienceUnauthorized(key, error))
      } else {
        dispatch(fetchingExperienceFailure(key, error))
      }
      throw error
    })
}

/**
 * Updates experience settings
 */
export function handleChangingExperienceSettings (updatedSettings, experience, dispatch) {
  dispatch(changingExperienceSettings(experience.key))
  return experienceApi
    .updateExperience(experience.id, updatedSettings)
    .then(response => {
      dispatch(changingExperienceSettingsSuccess(experience.key, response.data))
      return response
    })
    .catch(error => {
      dispatch(changingExperienceSettingsFailure(experience.key, error))
      throw error
    })
}

/**
 * Updates experience menu in redux store.
 */
export function updateExperienceMenu (key, menu) {
  return {
    type: UPDATE_EXPERIENCE_MENU,
    key,
    menu
  }
}

/**
 * Updates experience branding details and update the corresponding data in redux store.
 */
export function fetchAndHandleChangingExperienceBranding (updatedBranding, experience, dispatch) {
  const { branding } = experience

  const payload = {
    branding: {
      ...branding, // existing branding
      ...updatedBranding // updated branding overwrites the existing branding because...
      // In the case of a key collision, the right-most (last) object's value wins out
    }
  }

  dispatch(changingExperienceBranding(experience.key))
  return experienceApi
    .updateExperienceBranding(experience.id, payload)
    .then(response => {
      dispatch(changingExperienceBrandingSuccess(experience.key, response.data.branding))
      return response
    })
    .catch(error => {
      dispatch(changingExperienceBrandingFailure(experience.key, error))
      throw error
    })
}

/**
 * Updates experience social settings data in redux store.
 */
export function updateExperienceSocialSettings (key, socialSettings) {
  return {
    type: UPDATE_EXPERIENCE_SOCIAL_SETTINGS,
    key,
    socialSettings
  }
}

/**
 * Update the list of pages of an experience in redux store.
 *
 * @param key experience key
 * @param pages new list of pages
 * @returns {{pages, type: string, key}} data for reducer
 */
export function updateExperiencePages (key, pages) {
  return {
    type: UPDATE_EXPERIENCE_PAGES,
    key,
    pages
  }
}

export function handlePanelNameUpdate (key, pageId, panelId, name, dispatch) {
  dispatch(updatePanelName(key, pageId, panelId, name))
}

export function handlePanelSettingsUpdate (key, pageId, panelId, settings, dispatch) {
  dispatch(updatePanelSettings(key, pageId, panelId, settings))
}

export function handlePanelConfigUpdate (key, pageId, panelId, config, dispatch) {
  dispatch(updatePanelConfig(key, pageId, panelId, config))
}

export function fetchAndHandlePanelSettingsUpdate (experience, pageId, panelId, settings, dispatch) {
  // dispatch(fetchingUpdatePanelContent(key, pageId, panelId))
  return panelApi
    .updatePanelSettings(experience.id, pageId, panelId, settings)
    .then(response => {
      dispatch(updatePanelSettings(experience.key, pageId, panelId, settings))
      return response
    })
    .catch(error => {
      // dispatch(fetchingUpdatePanelContentFailure(key, pageId, panelId, error))
      throw error
    })
}

/***
 *  FOR NATIVE CONTENT APP ONLY - ContentEditorApp
 */
// For live preview of panel content
export function handlePanelContentUpdate (key, pageId, panelId, content, dispatch) {
  dispatch(updatePanelContent(key, pageId, panelId, content))
}

// for saving panel content and updating the view
export function fetchAndHandlePanelContentUpdate (key, pageId, panelId, content, dispatch) {
  // dispatch(fetchingUpdatePanelContent(key, pageId, panelId))
  return contentApi
    .postContent(content, panelId, pageId)
    .then(response => {
      dispatch(updatePanelContent(key, pageId, panelId, content))
      return response
    })
    .catch(error => {
      // dispatch(fetchingUpdatePanelContentFailure(key, pageId, panelId, error))
      throw error
    })
}

/**
 * FOR OTHER NATIVE APPS - EmbedApp, BiDashboardApp, etc
 */
export function fetchAndHandleUpdatePanelConfig (experience, pageId, panelId, config, dispatch) {
  if (dispatch) {
    return updatingPanelConfig(experience, pageId, panelId, config, dispatch)
  } else {
    return function (dispatch) {
      return updatingPanelConfig(experience, pageId, panelId, config, dispatch)
    }
  }
}

export function handleUpdatingPage (key, page, dispatch) {
  dispatch(updatingPage(key, page))
}

function updatingPage (key, page) {
  return {
    type: UPDATING_PAGE,
    key,
    page
  }
}

function updatingPanelConfig (experience, pageId, panelId, config, dispatch) {
  dispatch(fetchingUpdatePanelConfig(experience.key, pageId, panelId, config))

  return panelApi
    .updatePanelConfig(experience.id, pageId, panelId, config)
    .then(response => {
      dispatch(fetchingUpdatePanelConfigSuccess(experience.key, pageId, panelId, config))
      return response
    })
    .catch(error => {
      dispatch(fetchingUpdatePanelConfigFailure(experience.key, pageId, panelId, config, error))
      throw error
    })
}

function fetchingUpdatePanelConfig (key, pageId, panelId, config) {
  return {
    type: FETCHING_UPDATE_PANEL_CONFIG,
    key,
    pageId,
    panelId,
    config
  }
}

function fetchingUpdatePanelConfigFailure (key, pageId, panelId, config, error) {
  return {
    type: FETCHING_UPDATE_PANEL_CONFIG_FAILURE,
    key,
    pageId,
    panelId,
    config,
    error
  }
}

function fetchingUpdatePanelConfigSuccess (key, pageId, panelId, config) {
  return {
    type: FETCHING_UPDATE_PANEL_CONFIG_SUCCESS,
    key,
    pageId,
    panelId,
    config
  }
}

/**
 * Rows
 */
export function handleRowSettingsUpdate (key, pageId, rowId, settings, dispatch) {
  dispatch(updateRowSettings(key, pageId, rowId, settings))
}

function updateRowSettings (key, pageId, rowId, settings) {
  return {
    type: UPDATE_ROW_SETTINGS,
    key,
    pageId,
    rowId,
    settings
  }
}

/**
 * Update page status
 */
function updatePageStatus (key, pageId, status, navigation) {
  return {
    type: UPDATE_PAGE_STATUS,
    key,
    pageId,
    status,
    navigation
  }
}
export function handleUpdatePageStatus (key, pageId, status, navigation, dispatch) {
  dispatch(updatePageStatus(key, pageId, status, navigation))
}

/**
 * Delete page
 */
export function handleDeletingPage (experience, pageId, dispatch) {
  return pageApi
    .updatePageStatus(pageId, STATUS_TRASHED)
    .then(response => {
      dispatch(updatePageStatus(experience.key, pageId, STATUS_TRASHED))
      return response
    })
    .catch(error => {
      throw error
    })
}

/**
 * Purge page
 */
export function handlePurgingPage (experience, pageId, dispatch) {
  return pageApi
    .deletePage(experience.id, pageId)
    .then(response => {
      dispatch(purgingPage(experience, pageId))
      return response
    })
    .catch(error => {
      throw error
    })
}

function purgingPage (experience, pageId) {
  return {
    type: DELETING_PAGE,
    key: experience.key,
    pageId
  }
}

/**
 * Clone page
 */
export function handleCloningPage (experienceKey, sourcePageId, newPageName, dispatch) {
  return pageApi
    .clonePage(sourcePageId, newPageName)
    .then(response => {
      dispatch(cloningPage(experienceKey, response.data))
      return response
    })
    .catch(error => {
      throw error
    })
}

function cloningPage (experienceKey, page) {
  return {
    type: CLONING_PAGE,
    key: experienceKey,
    page
  }
}

export function handleGettingPage (pageId, experienceKey, dispatch) {
  return getPage(experienceKey, pageId)
    .then(response => {
      dispatch(gettingPage(pageId, experienceKey, response.data))
      return response
    })
    .catch(error => {
      throw error
    })
}

function gettingPage (pageId, experienceKey, page) {
  return {
    type: GETTING_PAGE,
    key: experienceKey,
    pageId: pageId,
    page
  }
}

/**
 * Create page
 */
export function handleCreatingPage (experience, data, dispatch) {
  return pageApi
    .createPage(experience.id, data)
    .then(response => {
      dispatch(creatingPage(experience, response.data))
      return response
    })
    .catch(error => {
      throw error
    })
}

function creatingPage (experience, page) {
  return {
    type: CREATING_PAGE,
    key: experience.key,
    page
  }
}

export function fetchAndHandleUpdatePageOrder (experience, pageId, actionType, dispatch) {
  // return function (dispatch) {
  dispatch(fetchingUpdatePageOrder(experience.key))

  const from = experience.order.indexOf(pageId)
  const to = actionType === 'moveup' ? from - 1 : from + 1

  const payload = {
    order: move(experience.order, from, to)
  }

  return spaceApi
    .updateSpace(experience.id, payload)
    .then(response => {
      if (response.status === 200) {
        dispatch(fetchingUpdatePageOrderSuccess(experience.key, payload.order))
      } else {
        throw ExperiencesException(`errors.spaces.space.updatingZoneOrder.status${response.status}`)
      }
    })
    .catch(error => dispatch(fetchingUpdatePageOrderFailure(experience.key, error)))
  // }
}

export const fetchAndHandleRenamePage = (experience, pageId, pageName, dispatch) => {
  // dispatch(fetchingRenamePage(experience.key))
  return pageApi
    .updatePage(experience.id, pageId, {
      name: pageName
    })
    .then(response => {
      if (response.status === 200) {
        dispatch(fetchingRenamePageSuccess(experience.key, pageId, pageName))
        return response
      } else {
        throw ExperiencesException(`errors.spaces.space.renameCurrentZone.status${response.status}`)
      }
    })
    .catch(error => {
      // dispatch(fetchingRenamePageFailure(experience.key, pageId, error))
      throw error
    })
}

function clearExperience () {
  return {
    type: CLEAR_EXPERIENCES
  }
}

export function logoutAndClear (dispatch) {
  dispatch(clearExperience())
}
