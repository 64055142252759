import React from 'react'
import { useWindowWidth } from '@react-hook/window-size'

import Search from '../../search/Search'

import './SearchDrawer.css'

const SearchDrawer = ({ isOpen, handleState }) => {
  const windowWidth = useWindowWidth({
    wait: 100,
    leading: false,
    initialWidth: 1440
  })
  const getDrawerStyle = () => {
    if (windowWidth >= 576) {
      return '400'
    } else {
      return windowWidth
    }
  }

  return isOpen && (
    <>
      <div className="blanket search-drawer" onClick={() => handleState(false)} />
      <div className="SearchDrawer" style={{ left: `calc(100% - ${getDrawerStyle()}px)` }}>
        <Search isInDrawer={true} handleState={handleState} />
      </div>
    </>
  )
}

export default SearchDrawer
