import axios from 'axios'
import isNumber from 'lodash/isNumber'
import isEmpty from 'lodash/isEmpty'

import i18n from '../../i18n'
import { getApiRoot } from '../utils/SystemUtils'

export function ApiException (message, error) {
  return {
    name: 'ApiException',
    message,
    error
  }
}

export function getHomepageUrl (currentUser, tenant) {
  if (isEmpty(currentUser)) {
    // user is anonymous
    if (isEmpty(tenant.homepage)) {
      return '/experiences'
    } else {
      return `/experiences/${tenant.homepage}`
    }
  } else {
    // user is logged in
    return '/'
  }
}

export function logout (tenantId) {
  // we should adopt some kind of "pluggable" pattern here.

  // log out of BI
  // biApi.logout()

  return axios
    .get(`${getApiRoot()}/api/${tenantId}/logout`)
    .then(response => {
      // this.token = ''
      delete axios.defaults.headers.common.Authorization
      return response
    })
    .catch(error => {
      throw error
    })
}

export default {
  // token: '',

  tenantId: '',

  tenantDomain: '',

  searchDebounceValue: 500,

  PAGINATION_DEFAULT_SIZE: 10,

  userHasPermission (user, permission) {
    return user.permissions.indexOf(permission) > -1
  },

  userHasRole (space, role) {
    return space && space.role === role
  },

  userIsOrgAdmin (user) {
    return user.permissions.indexOf('org-admin') > -1
  },

  formatDate (value) {
    if (isNumber(value)) {
      const when = new Date(value)
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }
      return when.toLocaleString(window.navigator.language, options)
    } else {
      return null
    }
  },

  getDate (timezone, timestamp) {
    if (timezone) {
      try {
        const options = {
          timeZone: timezone,
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }
        const formatter = new Intl.DateTimeFormat([], options)
        return formatter.format(timestamp)
      } catch (error) {
        return null
      }
    } else {
      return null
    }
  },

  isUrl (input) {
    return /^(https?):\/\/((?:[a-z0-9.-]|%[0-9A-F]{2}){3,})(?::(\d+))?((?:\/(?:[a-z0-9-._~!$&'()*+,;=:@]|%[0-9A-F]{2})*)*)(?:\?((?:[a-z0-9-._~!$&'()*+,;=:/?@]|%[0-9A-F]{2})*))?(?:#((?:[a-z0-9-._~!$&'()*+,;=:/?@]|%[0-9A-F]{2})*))?$/i.test(input)
  },

  getToken () {
    axios.defaults.withCredentials = true
    return axios
      .get(`${getApiRoot()}/api/token`, {
        headers: {
          'Tenant-Domain': window.location.hostname
        }
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getTenant () {
    axios.defaults.withCredentials = true
    return axios
      .get(`${getApiRoot()}/api/tenant`, {
        headers: {
          'Tenant-Domain': window.location.hostname
        }
      })
      .then(response => {
        // TODO - we get the tenant data from this remote call, so we can retrieve the tenant id
        // TODO - we should probably also update the Redux store, keeping it in a global variable for now.
        this.tenantId = response.data.tenant.id
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getSession () {
    return axios
      .get(`${getApiRoot()}/api/session`)
      .then(response => {
        return response
      })
  },

  updateSpace (spaceId, payload) {
    return axios
      .post(`${getApiRoot()}/api/spaces/${spaceId}`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  // doesn't get everything correctly
  getZone (spaceId, zoneId) {
    return axios
      .get(`${getApiRoot()}/api/spaces/${spaceId}/zones/${zoneId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  renameApp (spaceId, zoneId, appInstanceId, appInstanceName) {
    return axios
      .post(`${getApiRoot()}/api/spaces/${spaceId}/zones/${zoneId}/panels/${appInstanceId}`, { name: appInstanceName })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  addAppToLayoutCell (spaceId, cellId, payload) {
    return axios
      .put(`${getApiRoot()}/api/spaces/${spaceId}/cells/${cellId}/panels`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  deleteAppFromLayoutCell (spaceId, zoneId, cellId, appInstanceId) {
    return axios
      .delete(`${getApiRoot()}/api/spaces/${spaceId}/zones/${zoneId}/cells/${cellId}/panels/${appInstanceId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getSpaceMembers (spaceId) {
    return axios
      .get(`${getApiRoot()}/api/spaces/${spaceId}/members`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  setUserProperties (uid, properties) {
    return axios
      .post(`${getApiRoot()}/api/users/${uid}/properties`, properties)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  postLogin (credentials) {
    return axios
      .post(`${getApiRoot()}/api/login`, credentials, {
        headers: {
          'Content-Type': 'application/json',
          'Tenant-Domain': window.location.hostname
        }
      })
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
  },

  handleSession (response) {
    if (response.status && response.status === 200) {
      const session = response.data
      if (!isEmpty(session.profileAttributes.language)) {
        i18n.changeLanguage(session.profileAttributes.language)
      }

      return {
        ...session,
        uid: session.id
      }
    } else {
      throw ApiException('auth.error.getUser')
    }
  },

  /**
   * Used by`fetchAndHandleAuthedUser in `AuthenticateContainer`
   * @param {*} credentials
   */
  auth (credentials) {
    return this.postLogin(credentials)
      .then(response => {
        if (response.status && response.status === 200) {
          // this.token = response.data
          axios.defaults.headers.common.Authorization = response.data
          axios.defaults.withCredentials = true
          return this.getSession()
        } else {
          throw ApiException('auth.error.credentials')
        }
      })
      .then(response => this.handleSession(response))
    // don't catch - it messes with later promises
  },

  /**
   * Used by `fetchAndHandleIsUserAuthed` in `MainContainer` to check the current user token
   * @param {*} credentials
   */
  isAuthed () {
    return this.getToken()
      .then(response => {
        if (response.status && response.status === 200) {
          // this.token = response.data.token
          axios.defaults.headers.common.Authorization = response.data.token
          axios.defaults.withCredentials = true
          return this.getSession()
        } else {
          throw ApiException('auth.error.credentials')
        }
      })
      .then(response => this.handleSession(response))
      .catch(() => {
        axios.defaults.headers.common['Tenant-Domain'] = window.location.hostname
      })
  },

  searchUsersByName (query) {
    return axios
      .get(`${getApiRoot()}/api/search/users/${query}`)
      .then(response => {
        return response.data.map(user => {
          return {
            ...user,
            searchDisplayName: `${user.fullName} (${user.email})`
          }
        })
      })
      .catch(error => {
        throw error
      })
  },

  makeOrgAdminExperienceOwner (experienceId, adiGroupId) {
    const payload = {
      adiGroupId: adiGroupId
    }

    return axios
      .post(`${getApiRoot()}/api/admin/experiences/${experienceId}/members`, payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  addSpaceUserMember (userId, spaceId, adiGroupId, role) {
    const payload = {
      userId: userId,
      role: role,
      adiGroupId: adiGroupId,
      type: 'user'
    }

    return axios
      .put(`${getApiRoot()}/api/spaces/${spaceId}/members`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  addSpaceGroupMember (groupId, spaceId, adiGroupId, role) {
    const payload = {
      groupId: groupId,
      role: role,
      adiGroupId: adiGroupId,
      type: 'group'
    }

    return axios
      .put(`${getApiRoot()}/api/spaces/${spaceId}/members`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  deleteSpaceMember (spaceId, memberId) {
    return axios
      .delete(`${getApiRoot()}/api/spaces/${spaceId}/members/${memberId}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getUserPermissions (userId) {
    return axios
      .get(`${getApiRoot()}/api/users/${userId}/permissions`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  grantPermissionToUser (user, permission) {
    const payload = {
      user: user,
      permission: permission
    }

    return axios
      .post(`${getApiRoot()}/api/permissions/users`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  grantPermissionToGroup (group, permission) {
    const payload = {
      group: group,
      permission: permission
    }

    return axios
      .post(`${getApiRoot()}/api/permissions/groups`, payload, {
        'Content-Type': 'application/json'
      })
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  revokePermission (permissionId, permissionType) {
    return axios
      .delete(`${getApiRoot()}/api/permissions/${permissionId}/${permissionType}`)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  },

  getUsersWithPermission (permission) {
    return axios
      .get(`${getApiRoot()}/api/permissions/${permission}/users`)
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
  }
}
