import React, { useEffect, useState } from 'react'

import { Redirect } from 'react-router-dom'

import spaceApi from '../../../../common/apis/SpaceApi'
import contentApi from '../../../../common/apis/ContentApi'
import { ApiException } from '../../../../common/apis/Api'

/**
 * A redirect component to fix any problems with events already in ADI
 *
 * Redirects...
 *  - from: /spaces/:spaceId/posts/:adiContentId
 *  - to:   /experiences/:experienceKey/posts/:contentId
 */
const SpaceContentRedirect = ({ match }) => {
  const [experienceKey, setExperienceKey] = useState(null)
  const [redirectPath, setRedirectPath] = useState(null)

  const { spaceId, contentId } = match.params

  useEffect(() => {
    spaceApi
      .getSpaceById(spaceId)
      .then(response => {
        if (response.data && response.data.key) {
          setExperienceKey(response.data.key)
        }
      })
      .then(() => {
        contentApi
          .getContentItemByAdiId(contentId)
          .then(response => {
            console.log('getContentItemByAdiId', response)
            if (response.data && response.data.id) {
              setRedirectPath(`posts/${response.data.id}`)
            } else {
              throw ApiException('Failed to get real content id')
            }
          })
          .catch(() => {
            setRedirectPath('posts/#content-not-found')
          })
      })
  })

  return experienceKey && redirectPath ? <Redirect to={`/experiences/${experienceKey}/${redirectPath}`} /> : null
}

export default SpaceContentRedirect
