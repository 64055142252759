/**
 * Simple check to see whether the color is dark or light, so that the eyedropper can be given a contrasting color
 * @param {*} hexColor
 */
export function isDark (hexColor) {
  // adapted from https://gist.github.com/larryfox/1636338

  let r
  let b
  let g
  let hsp
  let a = hexColor.indexOf('#') === 0 ? hexColor.substring(1, hexColor.length) : hexColor

  if (a.match(/^rgb/)) {
    a = a.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
    r = a[1]
    g = a[2]
    b = a[3]
  } else {
    a = +(
      '0x' +
      a.slice(1).replace(
        // thanks to jed : http://gist.github.com/983661
        a.length < 5 && /./g,
        '$&$&'
      )
    )
    r = a >> 16
    b = (a >> 8) & 255
    g = a & 255
  }
  hsp = Math.sqrt(
    // HSP equation from http://alienryderflex.com/hsp.html
    0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
  )

  return hsp > 127.5
}

// toLuminanance([ r, g, b])
const toLuminanance = rgb => {
  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]
}

// contrastColor([ r, g, b])
export const contrastColor = rgb => {
  return toLuminanance(rgb) > 140 ? '#000000' : '#ffffff'
}

export function textColor (hexColor) {
  return isDark(hexColor) ? '#000000' : '#ffffff'
}

export const randomCssColor = () => {
  // To replace this:
  // which occationally failed with 5 characters rather than 6 characters for a hex value
  // '#' + ((Math.random() * 0xffffff) << 0).toString(16)

  const random256 = () => {
    return Math.floor(Math.random() * 256)
  }
  const color = `rgb(${random256()}, ${random256()}, ${random256()})`
  return color
}

export const hexToRgb = hex => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    }
    : null
}

// rgbToHex(0, 51, 255); // #0033ff
const componentToHex = c => {
  var hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

// rgbToHex(0, 51, 255); // #0033ff
export const rgbToHex = (r, g, b) => {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}
